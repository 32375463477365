import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import swal from 'sweetalert2';
import axios from 'axios';
import PuffLoader from "react-spinners/PuffLoader";

const AddAudioBulkFile = () => {
    const [files, setFiles] = useState([]); // Use files instead of file
    const [question_type, setQuestion_type] = useState("");
    const [loading, setLoading] = useState(false);

    const handleFileChange = (e) => {
        const selectedFiles = e.target.files;
        const filesArray = Array.from(selectedFiles);
        setFiles(filesArray); // Use setFiles to update the state
    };

    const handleUpload = async () => {
        if (question_type && files.length > 0) { // Check if files array is not empty
            try {
                setLoading(true);
                const formData = new FormData();
                files.forEach((file, index) => {
                    formData.append(`audio_file[${index}]`, file);
                });
                formData.append('question_type', question_type);

                const response = await axios.post('/api/v1/adm/question-segment-uploads', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setQuestion_type("");
                setFiles([]);
                setLoading(false);
                swal.fire({
                    title: 'Success',
                    text: 'Audio File uploaded successfully!',
                    icon: 'success',
                });
            } catch (error) {
                setLoading(false);
                setFiles([])
                swal.fire({
                    title: 'Error',
                    text: 'Unable to upload file. Please try again.',
                    icon: 'error',
                });
            }
        } else {
            swal.fire({
                title: 'Error',
                text: 'Choose Audio_Type and at least one file.',
                icon: 'error',
            });
        }
    };
    const handleGoBack = () => {
        window.history.back();
    };

    return (
        <Layout>
            <div className="page-content">
                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            {loading && (
                                <div className="data_loader">
                                    <PuffLoader color="#6571ff" />
                                </div>
                            )}
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div className="card-title add-bulk">File Upload</div>
                                    <button className="btn btn-primary mb-1" onClick={handleGoBack}>
                                        Back
                                    </button>
                                </div>
                                <div className="mb-3">
                                    <label
                                        htmlFor="questionType"
                                        className="form-label"
                                        title="Question Type"
                                    >
                                        Audio Type
                                    </label>
                                    <select
                                        className="form-select"
                                        id="questionType"
                                        aria-required="true"
                                        value={question_type}
                                        onChange={(e) => setQuestion_type(e.target.value)}
                                        name="question_type"
                                    >
                                        <option >select audio file</option>
                                        <option value="2">Question Audio</option>
                                        <option value="1">Sample Answer Audio</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="file" className="form-label">
                                        Choose Audio File
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="file"
                                        multiple
                                        onChange={handleFileChange}
                                    />
                                </div>
                                <div className="mb-3 mt-3">
                                    <div className="d-flex justify-content-center">
                                        <button type="button" className="btn btn-primary" onClick={handleUpload}>
                                            Upload
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default AddAudioBulkFile;
