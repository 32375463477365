import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import axios from "axios";
import { Link,useNavigate } from "react-router-dom";
import * as Icon from "react-feather";
import { FaRegStar, FaStar } from "react-icons/fa";
import swal from "sweetalert2";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import ClipLoader from "react-spinners/ClipLoader";
import PuffLoader from "react-spinners/PuffLoader";
import BounceLoader from "react-spinners/BounceLoader";

export default function Users() {
  const [userData, setUserData] = useState([]);
  const [showTotal, setTotalShow] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setotalPages] = useState(0);
  const [perPage, setperPage] = useState(10);

  const navigate = useNavigate();
  // const totalPages = 10;
  useEffect(() => {
    fetchData(currentPage, query, perPage);
  }, []);

  const fetchData = async (currentPage, query = "", perPage = "") => {
    const url = `api/v1/users/?search=${query}&page=${currentPage}&perpage=${perPage}`;

    try {
      const api = await axios(url);
      setLoading(false);
      // console.log(api.data.results);
      setUserData(api.data.results.data);
      // console.log(api.data.results.data);
      setCurrentPage(currentPage);
      setotalPages(api.data.results.last_page);
      setTotalShow("Showing " + api.data.results.from + " to " + api.data.results.to + " of " + api.data.results.total + " entries");
    } catch (err) {
      setLoading(false);
      console.log("something went wrong!");
    }
  };

  const handlePageClick = async data => {
    let currentPagex = data;
    console.log(currentPagex);
    setLoading(true);
    fetchData(currentPagex, query, perPage);
    // const commentsFormServer = await fetchComments(currentPage);
    // setItems(commentsFormServer);
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  const [query, setQuery] = useState("");

  const handleSearch = event => {
    setQuery(event.target.value);
    // searchUsers(event.target.value);
    setLoading(true);
    fetchData(0, event.target.value);
  };

  const handlePerPageChange = event => {
    setLoading(true);
    let perpage = event.target.value;
    setperPage(perpage);
    console.log(perpage);
    fetchData("", query, perpage);
  };

  const searchUsers = query => {
    axios
      .get(`/api/v1/users/?search=${query}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(response => {
        setUserData(response.data.results.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const deleteItem = async id => {
    console.log(id);
    try {
      const api = await axios.delete("/api/v1/users/delete/" + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      const newUserData = userData.filter(item => {
        return item.id !== id;
      });
      // alert(api.data.message);
      swal.fire("Deleted!", "User has been successfully Deleted.", "success");
      setUserData(newUserData);
    } catch (err) {
      // console.log("something went wrong!");
      swal.fire({
        title: "Error deleting item",
        text: err.message,
        icon: "error",
      });
    }
  };

  const handleDelete = id => {
    swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      .then(result => {
        if (result.isConfirmed) {
          deleteItem(id);
        }
      });
  };

  const handleStarClick = async (e, id) => {
    e.preventDefault();
    const userIndex = userData.findIndex((user) => user.id === id);
    const updatedUserData = [...userData];
    updatedUserData[userIndex].isStarred = !updatedUserData[userIndex]
      .isStarred;
    setUserData(updatedUserData);
    try {
      const response = await axios.put(`/api/v1/adm/vip-status?user_id=${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      // swal.fire("Success!", "Mock test has been successfully created.Proceed to add dialogus", "success");
      fetchData(currentPage, query, perPage);
    } catch (error) {
      swal.fire({
        title: "Unable to Add item",
        text: error.message,
        icon: "error",
      });
    }
  };

  const handleLogin = async (id) => {
    try {
        const result = await swal.fire({
            title: 'Are you sure?',
            text: 'Do you really want to login?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, login!',
            cancelButtonText: 'No, cancel!',
        });

        if (result.isConfirmed) {
            const response = await axios.post(`/api/v1/login-student-portal`, { id }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data.status === 200 && response.data.data) {
              const { name, token ,last_name,email,language,profile_image,phone_no} = response.data.data;
                const dashboardUrl = 'https://naaticcl.visionlanguageexperts.com.au/login';

                // Open dashboard in a new window
                const newWindow = window.open(dashboardUrl, '_blank');

                // Wait for a short delay to ensure the new window is fully loaded
                await new Promise(resolve => setTimeout(resolve, 1000));

                // Post token securely to the dashboard
                newWindow.postMessage({ name, token ,last_name,email,language,profile_image,phone_no}, 'https://naaticcl.visionlanguageexperts.com.au/');

                swal.fire({
                    title: 'Logged in!',
                    text: 'You have been successfully logged in.',
                    icon: 'success',
                });
            } else {
                throw new Error(response.data.message || 'Login failed. Please try again.');
            }
        }
    } catch (error) {
        swal.fire({
            title: 'Unable to login',
            text: error.message || 'Login failed. Please try again.',
            icon: 'error',
        });
    }
};

  return (
    <Layout>
      <div className="page-content">
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title text-start">User List</h6>
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-8"></div>
                  <div className="col-sm-12 col-md-4">
                    <div className="dataTables_paginate paging_simple_numbers" id="dataTableExample_paginate">
                      <input value={query} onChange={handleSearch} type="search" className="form-control float-end" placeholder="Search" aria-controls="dataTableExample" />
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  {loading ? (
                    <div className="data_loader">
                      <PuffLoader color="#6571ff" />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className="col-sm-12 col-md-4">
                    <label className="form-check-label mx-1">Show</label>
                    <select className="form-select form-select-sm w-25 d-sm-inline-block mx-2" name="perpage" onChange={handlePerPageChange}>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                    </select>
                    <label className="form-check-label"> entries</label>
                  </div>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>VIP</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Login</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData?.map((user, i) => {
                        return (
                          <tr key={i}>
                            <th>{user?.serial_no}</th>
                            <th>
                              <button
                                onClick={(e) => handleStarClick(e, user.id)}
                                className="btn"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Toggle Star"
                              >
                                {user.vip_status === 1 ? (
                                  <FaStar color="blue" size={18} />
                                ) : (
                                  <FaRegStar size={18} />
                                )}
                              </button>
                            </th>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td onClick={() => handleLogin(user.id)}>
                              <Icon.User className="link-icon" />
                            </td>
                            <td>Active</td>
                            <td>
                              <Link className="btn" to={`/users-view/${user.id}`} data-toggle="tooltip" data-placement="top" title="View Detail">
                                <Icon.Eye className="link-icon" />
                              </Link>
                              <Link className="btn" to={`/users-edit/${user.id}`} data-toggle="tooltip" data-placement="top" title="User Update">
                                <Icon.Edit className="link-icon" />
                              </Link>
                              <button onClick={() => handleDelete(user.id)} className="btn" data-toggle="tooltip" data-placement="top" title="Delete User">
                                <Icon.Trash className="link-icon" />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-7">
                    <div className="dataTables_info" id="dataTableExample_info" role="status" aria-live="polite">
                      {showTotal}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-5">
                    <div className="dataTables_paginate paging_simple_numbers" id="dataTableExample_paginate">
                      <ResponsivePagination current={currentPage} total={totalPages} onPageChange={handlePageClick} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
