// import type { FormInstance } from "antd";
import React, { useEffect, useState } from "react";
import { Form, Input, Button, Space, Select, message, Upload, Spin } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import * as Iconx from "@ant-design/icons";
// import * as Icon from "react-feather";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import swal from "sweetalert2";
const { Option } = Select;
// import PuffLoader from "react-spinners/PuffLoader";
// import { SmileOutlined, SolutionOutlined, UserOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
// const { Option } = Select;

function UpdateQuestionForm() {
  const { id } = useParams();

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [loginDetails, setLoginDetails] = useState(null);
  const [CategoryData, setCategoryData] = useState([]);
  const [LanguageData, setLanguageData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [audioSegment, setSegment] = useState([]);
  const [files, setFiles] = useState(null);
  const [selectedSegmentId, setSelectedSegmentId] = useState(null);
  const [selectedSegmentIdNew, setSelectedSegmentIdNew] = useState(null);
  const [textareas, setTextareas] = useState("");

  const handleButtonClick = (segmentId) => {
    setSelectedSegmentId(segmentId);
    // Trigger the file input click
    fileInputRef.click();
  };

  const handleButtonClicks = (segmentId) => {
    setSelectedSegmentIdNew(segmentId);
    // Trigger the file input click
    fileInputRefs.click();
  };

  const formRef = React.useRef(null);

  let fileInputRef; // Declare fileInputRef variable
  let fileInputRefs; // Declare fileInputRef variable

  const handleFileChange = async (e, ids) => {
    const selectedFile = e.target.files[0];
    // setFile(selectedFile);
    // Check if a file is selected
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append("audio_file", selectedFile);
        formData.append("segment_id", ids);
        const response = await axios.post('/api/v1/adm/segment-question-update', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },

        });
        setFiles(response?.data);
        message.success(`Audio File uploaded successfully!`);
      } catch (error) {
        message.error(`Unable to upload file. Please try again.`);
      }
    } else {
      message.warning("Please select a file");
    }
  };

  const handleFileChanges = async (e, ID) => {
    console.log("ids222", ID)
    const selectedFile = e.target.files[0];
    // setFile(selectedFile);
    // Check if a file is selected
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append("audio_file", selectedFile);
        formData.append("segment_id", ID);
        const response = await axios.post('/api/v1/adm/segment-answer-update', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },

        });
        setFiles(response?.data);
        message.success(`Audio File uploaded successfully!`);
      } catch (error) {
        message.error(`Unable to upload file. Please try again.`);
      }
    } else {
      message.warning("Please select a file");
    }
  };
  // console.log("1222", file);

  const fetchQuestion = async () => {
    try {
      const api = await axios.get("/api/v1/adm/questions/" + id);
      setApiData(api.data.results);
      localStorage.setItem("Mock_title", api?.data?.results?.question_details?.question_title)
      console.log(api.data.results);
      const results = api.data.results;
      setSegment(results.question_segment);
      formRef.current?.setFieldsValue({
        question_title: results.question_details.question_title,
        question_background: results.question_details.question_background,
        language: results.question_details.language_id,
        category: results.question_details.category_id,
        questionType: results.question_details.q_type,
        teachers_explanation_url: results.question_details.teachers_explanation_url,
      });
      console.log(results.question_segment);
    } catch (err) {
      console.log("Something Wrong");
    }
  };

  useEffect(() => {
    fetchQuestion();
  }, [id, files]);

  const handleGoBack = () => {
    window.history.back();
  };

  useEffect(() => {
    ListCategory("");
    FetchLanguage();
  }, []);

  const questiontype = [
    {
      label: "Interpreter",
      value: 1,
    },
    {
      label: "Translator",
      value: 2,
    },
  ];

  const FetchLanguage = () => {
    axios
      .get(`/api/v1/adm/languages`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(response => {
        //console.log(response.data.results);
        setLanguageData(response.data.results);
      })
      .catch(error => {
        console.log(error);
        swal.fire({
          title: "Unable to fetch data - Languages",
          text: error.message,
          icon: "error",
        });
      });
  };

  const ListCategory = query => {
    axios
      .get(`/api/v1/adm/category?search=${query}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(response => {
        //console.log(response.data.results);
        setCategoryData(response.data.results);
      })
      .catch(error => {
        console.log(error);
        swal.fire({
          title: "Unable to fetch data - Categories",
          text: error.message,
          icon: "error",
        });
      });
  };

  const onFinishFormStepOne = values => {
    console.log(audioUrlArray);
    // if (audioUrlArray.length === 0) {
    //   swal.fire({
    //     title: "Please enter the question and answer according to the prompts!",
    //     // text: error.message,
    //     icon: "warning",
    //   });
    //   return;
    // }

    try {
      const data = Object.assign({}, values, { audioDetails: audioUrlArray }, { question_id: id });

      const responce = axios.post("/api/v1/adm/question-update", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      console.log(responce);
      // alert("User successfully created!");
      swal.fire("Success!", "Question has been successfully created.", "success");
      navigate("/questions");
      //setLoading(true);
    } catch (error) {
      console.log(error.response.status);
      console.log("Something Wrong");
      if (error.response.status === 422) {
        // setErrors(error.response.data.errors);
      } else {
        swal.fire({
          title: "Unable to Add item",
          text: error.message,
          icon: "error",
        });
      }
    }
  };

  let [selectedValue, setSelectedValue] = useState("");

  const onLanguageChange = value => {
    console.log(value);
    setSelectedValue(value);
    localStorage.setItem("selectedValue", value);
  };

  const [audioUrlArray, setAudioUrlArray] = useState([]);
  // const [audioUrlArrayB, setAudioUrlArrayB] = useState([]);
  const [ReferenceArray, setReferenceArray] = useState([]);
  const [AudioReferenceArray, setAudioReferenceArray] = useState([]);
  const [PersonArray, setPersonArray] = useState([]);

  const [audioUrl, setAudioUrl] = useState("");
  const [audioUrlB, setAudioUrlB] = useState("");

  const [audio_file, setAudio_file] = useState("");
  const [audio_fileB, setAudio_fileB] = useState("");

  const [audioReferA, setAudioReferA] = useState("");
  const [audioReferB, setAudioReferB] = useState("");

  const [audio_fileRefrence, setAudio_fileRefrence] = useState("");
  const [audio_fileRefrenceB, setAudio_fileRefrenceB] = useState("");

  const [divs, setDivs] = useState([]);
  const [divsB, setDivsB] = useState([]);

  const [loading, setLoading] = useState(false);

  // const [size, setSize] = useState("large");
  const { TextArea } = Input;

  const removeDiv = index => {
    const newDivs = [...divs];
    newDivs.splice(index, 1);
    setDivs(newDivs);
  };

  const removeDivB = index => {
    const newDivsB = [...divsB];
    newDivsB.splice(index, 1);
    setDivsB(newDivsB);
  };

  const { Dragger } = Upload;

  const draggerProps = {
    name: "file",
    multiple: false,
    showUploadList: false,
    beforeUpload: file => {
      const isPNG = file.type === "audio/mpeg";
      if (!isPNG) {
        message.error(`${file.name} is not a audio file`);
      }
      return isPNG || Upload.LIST_IGNORE;
    },
    customRequest: ({ onSuccess, onError, file }) => {
      setAudioUrl("");
      setLoading(true);
      const formData = new FormData();
      formData.append("audiotype", "question");
      formData.append("file", file);
      axios
        .post(`api/v1/adm/uploads`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        })
        .then(response => {
          setLoading(false);
          console.log(response.data.status);
          message.success(`${file.name} file uploaded successfully.`);
          // setAudioUrl(response.data.file_path);
          setAudioUrl(response.data.file_path);
          setAudio_file(response.data.audio_file);
          // swal.fire("Success!", "Word has been successfully updated.", "success");
          // navigate("/words");
        })
        .catch(error => {
          setLoading(false);
          setAudioUrl("");
          message.error(`${file.name} file upload failed.`);
          console.log(error);
          if (error.response.status === 422) {
            // setErrors(error.response.data.errors);
          } else {
          }
        });
    },
  };

  const BProps = {
    name: "file",
    multiple: false,
    showUploadList: false,
    beforeUpload: file => {
      const isPNG = file.type === "audio/mpeg";
      if (!isPNG) {
        message.error(`${file.name} is not a audio file`);
      }
      return isPNG || Upload.LIST_IGNORE;
    },
    customRequest: ({ onSuccess, onError, file }) => {
      setAudioUrlB("");
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("audiotype", "question");
      axios
        .post(`api/v1/adm/uploads`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        })
        .then(response => {
          setLoading(false);
          console.log(response.data.status);
          message.success(`${file.name} file uploaded successfully.`);
          setAudioUrlB(response.data.file_path);
          setAudio_fileB(response.data.audio_file);
          // swal.fire("Success!", "Word has been successfully updated.", "success");
          // navigate("/words");
        })
        .catch(error => {
          setLoading(false);
          setAudioUrlB("");
          message.error(`${file.name} file upload failed.`);

          console.log(error);
          if (error.response.status === 422) {
            // setErrors(error.response.data.errors);
          } else {
          }
        });
    },
  };

  const ReferPropsA = {
    name: "file",
    multiple: false,
    showUploadList: false,
    beforeUpload: file => {
      const isPNG = file.type === "audio/mpeg";
      if (!isPNG) {
        message.error(`${file.name} is not a audio file`);
      }
      return isPNG || Upload.LIST_IGNORE;
    },
    customRequest: ({ onSuccess, onError, file }) => {
      setAudioReferA("");
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("audiotype", "sample-answer-audio");
      axios
        .post(`api/v1/adm/uploads`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        })
        .then(response => {
          setLoading(false);
          console.log(response.data.status);
          message.success(`${file.name} file uploaded successfully.`);
          // setAudioUrl(response.data.file_path);
          setAudioReferA(response.data.file_path);
          setAudio_fileRefrence(response.data.audio_file);
          // swal.fire("Success!", "Word has been successfully updated.", "success");
          // navigate("/words");
        })
        .catch(error => {
          setLoading(false);
          setAudioReferA("");
          message.error(`${file.name} file upload failed.`);

          console.log(error);
          if (error.response.status === 422) {
            // setErrors(error.response.data.errors);
          } else {
          }
        });
    },
  };

  const UploadChange = {
    name: "file",
    multiple: false,
    showUploadList: false,
    beforeUpload: file => {
      const isPNG = file.type === "audio/mpeg";
      if (!isPNG) {
        message.error(`${file.name} is not a audio file`);
      }
      return isPNG || Upload.LIST_IGNORE;
    },
    customRequest: ({ onSuccess, onError, file }) => {
      setAudioReferA("");
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("audiotype", "sample-answer-audio");
      axios
        .post(`api/v1/adm/uploads`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        })
        .then(response => {
          setLoading(false);
          console.log(response.data.status);
          message.success(`${file.name} file uploaded successfully.`);
          // setAudioUrl(response.data.file_path);
          setAudioReferA(response.data.audio_file);
          // swal.fire("Success!", "Word has been successfully updated.", "success");
          // navigate("/words");
        })
        .catch(error => {
          setLoading(false);
          setAudioReferA("");
          message.error(`${file.name} file upload failed.`);

          console.log(error);
          if (error.response.status === 422) {
            // setErrors(error.response.data.errors);
          } else {
          }
        });
    },
  };

  const ReferPropsB = {
    name: "file",
    multiple: false,
    showUploadList: false,
    beforeUpload: file => {
      const isPNG = file.type === "audio/mpeg";
      if (!isPNG) {
        message.error(`${file.name} is not a audio file`);
      }
      return isPNG || Upload.LIST_IGNORE;
    },
    customRequest: ({ onSuccess, onError, file }) => {
      setAudioReferB("");
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("audiotype", "sample-answer-audio");
      axios
        .post(`api/v1/adm/uploads`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        })
        .then(response => {
          setLoading(false);
          console.log(response.data.status);
          message.success(`${file.name} file uploaded successfully.`);
          // setAudioUrl(response.data.file_path);
          setAudioReferB(response.data.file_path);
          setAudio_fileRefrenceB(response.data.audio_file);
          // swal.fire("Success!", "Word has been successfully updated.", "success");
          // navigate("/words");
        })
        .catch(error => {
          setLoading(false);
          setAudioReferB("");
          message.error(`${file.name} file upload failed.`);

          if (error.response.status === 422) {
            // setErrors(error.response.data.errors);
          } else {
          }
        });
    },
  };

  const [ReferenceA, setReferenceA] = useState("");
  const [ReferenceB, setReferenceB] = useState("");

  const url_audio_quest = "http://localhost:8000/uploads/questions/";
  const url_audio_answer = "http://localhost:8000/uploads/audio-answers/";

  const audio_urls = audioUrl;
  const audio_urlsB = audioUrlB;

  const audio_ReferA = audioReferA;
  const audio_ReferB = audioReferB;

  const handleChangeReferenceA = event => {
    setReferenceA(event.target.value);
    console.log(event.target.value);
  };

  function handleChangeReferenceB(e) {
    // ✅ Updating a controlled input to e.target.value synchronously
    setReferenceB(e.target.value);
  }
  // const handleChangeReferenceB = event => {
  //   setReferenceB(event.target.value);
  //   console.log(event.target.value);
  // };

  const addDiv = () => {
    if (audio_file === "") {
      alert("Please Upload Audio For Person-A.");
    } else if (ReferenceA === "") {
      alert("Please Enter Reference Answer A");
    } else {
      // const combineMy = [audioUrl, ReferenceA, audio_ReferA, "person-A"];
      // const data = Object.assign({ audioName: audioUrl }, { reference: ReferenceA }, { audioAnswer: audioReferA }, { personType: "person-A" });
      // console.log(data);
      // const mydata = data;
      const data = {
        audioName: audio_file,
        reference: ReferenceA,
        audioAnswer: audio_fileRefrence,
        personType: "person-A",
      };

      const mydata = Object.assign({}, data);
      setAudioUrlArray([...audioUrlArray, mydata]);
      // const dataArray = Object.entries(data).map(([key, value]) => ({ key, value }));
      // console.log(dataArray);
      // setReferenceArray([...ReferenceArray, ReferenceA]);
      // setAudioReferenceArray([...AudioReferenceArray, audio_ReferA]);
      // setPersonArray([...PersonArray, "person-A"]);
      setDivs([
        ...divs,
        <div key={divs.length}>
          <span className="mock-block">
            <audio controls src={audio_urls}></audio>
            <p className="mt-0 mb-1">Reference Answers</p>
            <p className="text-border">{ReferenceA}</p>
            <p>
              <audio controls src={audio_ReferA}></audio>
            </p>
          </span>
        </div>,
      ]);
      setAudioUrl("");
      setAudioReferA("");
      setReferenceA("");
    }
  };

  const addDivB = () => {
    if (audio_fileB === "") {
      alert("Please Upload Audio For Person-B.");
    } else if (ReferenceB === "") {
      alert("Please Enter Reference Answer B");
    } else {
      // setAudioUrlArray([...audioUrlArray, audioUrlB]);
      // setReferenceArray([...ReferenceArray, ReferenceB]);
      // setAudioReferenceArray([...AudioReferenceArray, audio_ReferB]);
      // setPersonArray([...PersonArray, "person-B"]);
      // const data = Object.assign({ audioName: audioUrlB }, { reference: ReferenceB }, { audioAnswer: audioReferB }, { personType: "person-B" });
      const data = {
        audioName: audio_fileB,
        reference: ReferenceB,
        audioAnswer: audio_fileRefrenceB,
        personType: "person-B",
      };

      // console.log(data);
      const mydata = Object.assign({}, data);
      setAudioUrlArray([...audioUrlArray, mydata]);

      setDivsB([
        ...divsB,
        <div key={divs.length}>
          <span className="mock-block">
            <audio controls src={audio_urlsB}></audio>
            <p className="mt-0 mb-1">Reference Answers</p>
            <p className="text-border">{ReferenceB}</p>
            <p>
              <audio controls src={audio_ReferB}></audio>
            </p>
          </span>
        </div>,
      ]);
      setAudioUrlB("");
      setAudioReferB("");
      setReferenceB("");
    }
  };

  const textValues = Object.values(textareas);

  const handleTextareaChange = (id, value) => {
    setTextareas((prevTextareas) => ({
      ...prevTextareas,
      [id]: value,
    }));
  };

  const handleSaveTextarea = async id => {

    try {
      const data = {
        segment_id: id,
        audio_reference_text: textValues[0]
      }
      const response = await axios.post('/api/v1/adm/segment-text-update', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setTextareas("")
            setFiles(response?.data);
      message.success(`Refrence answer updated successfully.`);
    } catch (error) {
      message.error(`somthing went roungh! Refrence answer not updated.`);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="card-title add-bulk col-md-9 col-lg-9 text-start">Edit Question</div>
        <Link to={`/add-question/audio/${id}`}>
          <button className="btn btn-primary mb-1 ">
            Add Bulk Questions
          </button>
        </Link>
        <button className="btn btn-primary mb-1" onClick={handleGoBack}>
          Back
        </button>
      </div>
      <Form ref={formRef} form={form} layout="vertical" onFinish={onFinishFormStepOne} initialValues={loginDetails}>
        <h5>Question Type&Info</h5> {apiData.question_title}
        <Form.Item
          name="question_title"
          label="Question keywords (please enter meaningful keywords, the number of words should not exceed 50, which is convenient for search later)"
          rules={[{ required: true, message: "Please enter the Question keywords!" }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="question_background"
          label="Question background (please enter the topic background introduction)"
          rules={[{ required: true, message: "Please enter the Question description!" }]}>
          <Input />
        </Form.Item>
        <Form.Item name="teachers_explanation_url" label="Teacher's explanation address (please enter the full video URL)">
          <Input />
        </Form.Item>
        <Form.Item name="questionType" label="Question Type" rules={[{ required: true, message: "Please select question type!" }]}>
          <Select placeholder="Select a option and change input text above" allowClear style={{ textAlign: 'left' }}>
            {questiontype.map((type, i) => {
              return (
                <Option selected={apiData.questionType === type.value} value={type.value}>
                  {type.label}
                </Option>
              );
            })}
            {/* <Option selected={apiData.questionType === 2} value="2">
              Translator
            </Option> */}
          </Select>
        </Form.Item>
        <Form.Item name="category" label="Choose Category" rules={[{ required: true }]}>
          <Select placeholder="Please Select" allowClear style={{ textAlign: 'left' }}>
            <option value="">Please Select</option>
            {CategoryData.map((category, i) => {
              return (
                <Option selected={apiData.category === category.id} value={category.id}>
                  {category.category_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="language" label="Select Lanaguge" rules={[{ required: true }]}>
          <Select value={selectedValue} onChange={onLanguageChange} placeholder="Select a option and change input text above" style={{ textAlign: 'left' }}>
            <option value="">Please Select</option>
            {LanguageData.map((language, i) => {
              return (
                <Option selected={apiData.language === language.id} value={language.id}>
                  {language.language_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <div>
          <h5 className="mb-2">Please enter the question and answer according to the prompts: </h5>
          <div>
            <div className="container">
              <div className="row">
                <div className="col-md-6 stretch-card grid-margin grid-margin-md-0">
                  <div className="card">
                    <div className="card-body">
                      <p className="text-center mt-3 mb-1">Interpretation problem audio file upload (person A)</p>
                      <div className="card-text mb-3">
                        <Spin spinning={loading}>
                          <Dragger {...draggerProps}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Drag and drop the file here, or click to select the file to upload</p>
                          </Dragger>
                        </Spin>
                        {audioUrl && <audio controls src={audio_urls}></audio>}
                        <p className=" mt-3 mb-1">Reference answer recording text</p>
                        <p className="card-text mb-3">
                          <Form.Item label="">
                            <TextArea id="ReferenceA" name="ReferenceA" value={ReferenceA} onChange={handleChangeReferenceA} rows={4} />
                          </Form.Item>
                        </p>
                        <p className=" mt-3 mb-1">Reference answer recording upload</p>
                        <div className="card-text mb-3">
                          <Spin spinning={loading}>
                            <Dragger {...ReferPropsA}>
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                              </p>
                              <p className="ant-upload-text">Drag and drop the file here, or click to select the file to upload</p>
                            </Dragger>
                          </Spin>
                          {audioReferA && <audio controls src={audio_ReferA}></audio>}
                        </div>
                        <Button onClick={addDiv} className="mt-2" type="primary" icon={<Iconx.PlusCircleOutlined />} danger>
                          ADD
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <p className="text-center mt-3 mb-1">Interpretation problem audio file upload (person B)</p>
                      <div className="card-text mb-3">
                        <Spin spinning={loading}>
                          <Dragger {...BProps}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Drag and drop the file here, or click to select the file to upload</p>
                          </Dragger>
                        </Spin>
                        {audioUrlB && <audio controls src={audio_urlsB}></audio>}
                        <p className=" mt-3 mb-1">Reference answer recording text</p>
                        <p className="card-text mb-3">
                          <Form.Item label="">
                            <TextArea id="ReferenceB" name="ReferenceB" value={ReferenceB} onChange={handleChangeReferenceB} rows={4} />
                          </Form.Item>
                        </p>
                        <p className=" mt-3 mb-1">Reference answer recording upload</p>
                        <div className="card-text mb-3">
                          <Spin spinning={loading}>
                            <Dragger {...ReferPropsB}>
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                              </p>
                              <p className="ant-upload-text">Drag and drop the file here, or click to select the file to upload</p>
                            </Dragger>
                          </Spin>
                          {audioReferB && <audio controls src={audio_ReferB}></audio>}
                        </div>
                        <Button onClick={addDivB} className="mt-2" type="primary" icon={<Iconx.PlusCircleOutlined />} success>
                          ADD
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-6 stretch-card grid-margin grid-margin-md-0 mt-5">
                  <div className="card">
                    <div className="card-body">
                      <p className="mt-0 mb-1">Person 'A' Audios</p>
                      {audioSegment.map((divA, index) => {
                        // {divA.person_type &&
                        return (
                          <div key={index}>
                            {divA.person_type === "A" && (
                              <div className="space-align-block audio_blocks">
                                <Space align="center">
                                  {/* <Button onClick={() => removeDiv(index)} size="small" type="primary" danger>
                                    <Iconx.DeleteOutlined />
                                  </Button> */}
                                  {/* {getdiv.audio_file} */}
                                  <div key={divA.length}>
                                    <span className="mock-block">
                                      <audio controls src={`${divA.audio_file}`}></audio>
                                      {/* <Button className="ml-2" icon={<Iconx.UploadOutlined />}>
                                        Click to change
                                      </Button> */}
                                      {/* Use onClick to trigger the file input click */}
                                      <Button
                                        className="ml-2"
                                        icon={<Iconx.UploadOutlined />}
                                        onClick={() => handleButtonClick(divA.segment_id)}
                                      >
                                        Click to change
                                      </Button>
                                      <input
                                        type="file"
                                        ref={(input) => (fileInputRef = input)}
                                        style={{ display: "none" }}
                                        onChange={(e) => handleFileChange(e, selectedSegmentId)}
                                      />
                                      <p className="mt-0 mb-1">Reference Answers</p>
                                      <p className="text-border">
                                        {/* {divA.audio_reference_text} */}
                                        {/* <textarea
                                          name="reference"
                                          defaultValue={divA.audio_reference_text}
                                          onChange={e => setTextareas(e.target.defaultValue)}
                                          rows={4}
                                          cols={40}
                                        /> */}
                                        <textarea
                                          name={`textarea-${divA.segment_id}`}
                                          value={textareas[divA.segment_id] || divA.audio_reference_text}
                                          onChange={(e) => handleTextareaChange(divA.segment_id, e.target.value)}
                                          // name="reference"
                                          // value={String(divA.audio_reference_text) || textareas}
                                          // // Use divA.audio_reference_text if available, otherwise use textareas
                                          // onChange={(e) => setTextareas(e.target.value)}
                                          rows={4}
                                          cols={40}
                                        />
                                        <Button onClick={() => handleSaveTextarea(divA.segment_id)} className="" size="large" type="">
                                          <Iconx.SaveOutlined />
                                        </Button>
                                      </p>
                                      <p className="mt-3">
                                        <audio controls src={`${divA.sample_answer_audio}`}></audio>
                                        <Button
                                          className="ml-2"
                                          icon={<Iconx.UploadOutlined />}
                                          onClick={() => handleButtonClicks(divA.segment_id)}
                                        >
                                          Click to change
                                        </Button>
                                        {/* Hidden file input */}
                                        <input
                                          type="file"
                                          ref={(input) => (fileInputRefs = input)}
                                          style={{ display: "none" }}
                                          onChange={(e) => handleFileChanges(e, selectedSegmentIdNew)}
                                        />
                                      </p>
                                    </span>
                                  </div>
                                </Space>
                              </div>
                            )}
                          </div>
                        );
                      })}
                      {divs.map((div, index) => (
                        <div key={index}>
                          <div className="space-align-block audio_blocks">
                            <Space align="center">
                              <Button onClick={() => removeDiv(index)} size="small" type="primary" danger>
                                <Iconx.DeleteOutlined />
                              </Button>
                              {div}
                            </Space>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 stretch-card grid-margin grid-margin-md-0 mt-5">
                  <div className="card">
                    <div className="card-body">
                      <p className="mt-0 mb-1">Person 'B' Audios</p>

                      {audioSegment.map((divB, i) => (
                        // {divA.person_type &&
                        <div key={i}>
                          {divB.person_type === "B" && (
                            <div className="space-align-block audio_blocks">
                              <Space align="center">
                                {/* <Button onClick={() => removeDiv(i)} size="small" type="primary" danger>
                                  <Iconx.DeleteOutlined />
                                </Button> */}
                                {/* {getdiv.audio_file} */}

                                <div key={divB.length}>
                                  <span className="mock-block">
                                    <audio controls src={`${divB.audio_file}`}></audio>
                                    <Button
                                      className="ml-2"
                                      icon={<Iconx.UploadOutlined />}
                                      onClick={() => handleButtonClick(divB.segment_id)}
                                    >
                                      Click to change
                                    </Button>
                                    <input
                                      type="file"
                                      ref={(input) => (fileInputRef = input)}
                                      style={{ display: "none" }}
                                      onChange={(e) => handleFileChange(e, selectedSegmentId)}
                                    />
                                    <p className="mt-0 mb-1">Reference Answers</p>

                                    {/* <p className="text-border">{divB.audio_reference_text}</p> */}
                                    <textarea
                                      name={`textarea-${divB.segment_id}`}
                                      value={textareas[divB.segment_id] || divB.audio_reference_text}
                                      onChange={(e) => handleTextareaChange(divB.segment_id, e.target.value)}
                                      // name="reference"
                                      // value={String(divA.audio_reference_text) || textareas}
                                      // // Use divA.audio_reference_text if available, otherwise use textareas
                                      // onChange={(e) => setTextareas(e.target.value)}
                                      rows={4}
                                      cols={40}
                                    />
                                    <Button onClick={() => handleSaveTextarea(divB.segment_id)} className="" size="large" type="">
                                      <Iconx.SaveOutlined />
                                    </Button>
                                    <p className="mt-3">
                                      <audio controls src={`${divB.sample_answer_audio}`}></audio>
                                    </p>
                                    <Button
                                      className="ml-2"
                                      icon={<Iconx.UploadOutlined />}
                                      onClick={() => handleButtonClicks(divB.segment_id)}
                                    >
                                      Click to change
                                    </Button>
                                    {/* Hidden file input */}
                                    <input
                                      type="file"
                                      ref={(input) => (fileInputRefs = input)}
                                      style={{ display: "none" }}
                                      onChange={(e) => handleFileChanges(e, selectedSegmentIdNew)}
                                    />
                                  </span>
                                </div>
                              </Space>
                            </div>
                          )}
                        </div>
                      ))}

                      {divsB.map((div, index) => (
                        <div key={index}>
                          <div className="space-align-block audio_blocks">
                            <Space align="center">
                              <Button onClick={() => removeDivB(index)} size="small" type="primary" danger>
                                <Iconx.DeleteOutlined />
                              </Button>
                              {div}
                            </Space>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-text mb-3 mt-3">
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Space>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}

// function Finish() {
//   // const fsubmit = () => {
//   //   alert("done");
//   // };

export default UpdateQuestionForm;
