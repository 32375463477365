import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert2";
import axios from "axios";

export default function Login() {
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
 
  const makeRequest = e => {
    e.preventDefault();
    axios.get("/sanctum/csrf-cookie").then(() => {
      const payload = {
        email,
        password,
      };
      if (remember) {
        payload.remember = true;
      }
      axios
        .post("/api/login", payload, { headers: { Accept: "application/json" } })
        .then(response => {
          setErrors({});
          if (response.data.results) {
            if (response.data.status === 200) {
              localStorage.setItem("auth_token", response.data.results.token);
              localStorage.setItem("auth_name", response.data.results.name);
              localStorage.setItem("auth_email", response.data.results.email);
              localStorage.setItem("user_id", response.data.results.user_id);
              localStorage.setItem("role", response.data.results.role);
              swal.fire("Success!", "Login successfully!.", "success");
              navigate("/dashboard");
            }
          }
        })
        .catch(error => {
          setErrors({});
          console.log(error.status);
          if (error.response) {
            if (error.response.data.message) {
              setMessage(error.response.data.message);
              console.log(error.response.data.message);
            }
            if (error.response.data.errors) {
              console.log(error.response.data.errors);
              setErrors(error.response.data.errors);
            }
          }
        });
    });
  };

  return (
    <div>
      <div className="main-wrapper">
        <div className="page-wrapper full-page">
          <div className="page-content d-flex align-items-center justify-content-center">
            <div className="row w-100 mx-0 auth-page">
              <div className="col-md-8 col-xl-6 mx-auto">
                <div className="card">
                  <div className="row">
                    <div className="col-md-4 pe-md-0">
                      <div className="auth-side-wrapper"></div>
                    </div>
                    <div className="col-md-8 ps-md-0">
                      <div className="auth-form-wrapper px-4 py-5">
                       <div> NAATI CCL <span>ADMIN</span></div>
                         
                        
                        <h5 className="text-muted fw-normal mb-4">Welcome back! Log in to your account.</h5>
                        {message && <div className="alert alert-danger">{message}</div>}
                        <form onSubmit={makeRequest} className="forms-sample">
                          <div className="mb-3">
                            <label htmlFor="userEmail" className="form-label">
                              Email address
                            </label>
                            <input name="email" value={email} onChange={e => setEmail(e.target.value)} type="email" className="form-control" id="userEmail" placeholder="Email" />
                            {errors.email && <div className="text-danger mt-1">{errors.email[0]}</div>}
                          </div>
                          <div className="mb-3">
                            <label htmlFor="userPassword" className="form-label">
                              Password
                            </label>
                            <input
                              name="password"
                              type="password"
                              value={password}
                              onChange={e => setPassword(e.target.value)}
                              className="form-control"
                              id="userPassword"
                              placeholder="Password"
                            />
                            {errors.password && <div className="text-danger mt-1">{errors.password[0]}</div>}
                          </div>
                          <div className="form-check mb-3 text-start">
                            <input
                              name="remember"
                              onChange={e => {
                                setRemember(e.target.checked ? 1 : 0);
                              }}
                              type="checkbox"
                              className="form-check-input"
                              id="authCheck"
                            />
                            <label className="form-check-label text-start" htmlFor="authCheck">
                              Remember me
                            </label>
                          </div>
                          <div>
                            <button type="submit" className="btn btn-primary me-2 mb-2 mb-md-0 text-white">
                              Login
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
