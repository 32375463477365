import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import swal from "sweetalert2";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import PuffLoader from "react-spinners/PuffLoader";
import { AiOutlineFilter } from "react-icons/ai";

export default function Question() {
  const [wordData, setwordData] = useState([]);
  const [showTotal, setTotalShow] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setperPage] = useState(10);
  const [totalPages, setotalPages] = useState(0);

  const [language_name, setLanguage_name] = useState("");
  const [languages, setLanguages] = useState([]);
  const [query, setQuery] = useState("");
  const [statusf, setStatusf] = useState("");

  const handleLanguageSelect = (id) => {
    setLanguage_name(id);
  }

  useEffect(() => {
    fetchData(currentPage, query, perPage, language_name);
  }, [currentPage, query, perPage, language_name, statusf]);

  console.log("language_name", language_name);
  const fetchData = async (currentPage, query = "", perPage = "", language_name) => {
    const data = {
      language_name,
      query
    }
    const url = `api/v1/adm/questions/?search=${data.query}&language_name=${data.language_name}&page=${currentPage}&perpage=${perPage}`;
    try {
      const api = await axios(url);
      setLoading(false);
      // console.log(api.data.results);
      setwordData(api.data.results.data);
      setCurrentPage(currentPage);
      setotalPages(api.data.results.last_page);
      setTotalShow("Showing " + api.data.results.from + " to " + api.data.results.to + " of " + api.data.results.total + " entries");
    } catch (err) {
      setLoading(false);
      console.log("something went wrong!");
    }
  };


  const 
  FetchLanguage = () => {
    axios
      .get(`/api/v1/languages`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => {
        //console.log(response.data.results);
        setLanguages(response.data.results);
      })
      .catch(error => {
        swal.fire({
          title: "Unable to fetch data - Languages",
          text: error.message,
          icon: "error",
        });
      });
  };

  useEffect(() => {
    FetchLanguage();
  }, []);



  const handlePageClick = async data => {
    let currentPagex = data;
    // console.log(currentPagex);
    setLoading(true);
    // setperPage(10);
    fetchData(currentPagex, query, perPage,language_name);
  };



  const handleSearch = event => {
    setQuery(event.target.value);
    setLoading(true);
    fetchData(0, event.target.value);
  };

  const handlePerPageChange = event => {
    setLoading(true);
    let perpage = event.target.value;
    setperPage(perpage);
    console.log(perpage);
    fetchData(0, event.target.value, perPage, language_name);
  };

  const handleFreeStatusChange = async (id) => {
    axios
      .put(`/api/v1/adm/question-free-status?question_id=${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => {
        console.log(response.data.status);
        setStatusf(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const deleteItem = async id => {
    console.log(id);
    try {
      const api = await axios.delete("/api/v1/adm/questions/" + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      const newwordData = wordData.filter(item => {
        return item.id !== id;
      });
      // alert(api.data.message);
      swal.fire("Deleted!", "word has been successfully Deleted.", "success");
      setwordData(newwordData);
    } catch (err) {
      // console.log("something went wrong!");
      swal.fire({
        title: "Error deleting item",
        text: err.message,
        icon: "error",
      });
    }
  };

  const handleDelete = id => {
    swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      .then(result => {
        if (result.isConfirmed) {
          deleteItem(id);
        }
      });
  };

  const handleSwitchToggle = async (id) => {
    try {
      await axios.put(`/api/v1/adm/question-status?question_id=${id}`);
      swal.fire("Updated!", "Status has been updated successfully.", "success");
      fetchData(currentPage, query, perPage, language_name);
    } catch (error) {
      // Handle errors, show an error message, etc.
      console.error("Error updating switch status:", error);
      swal.fire({
        title: "Error",
        text: "Failed to update switch status.",
        icon: "error",
      });
    }
  };
  const handleSwitchVIP = async (id) => {
    try {
      await axios.put(`/api/v1/adm/question-vip-status?question_id=${id}`);
      // swal.fire("Updated!", "Status has been updated successfully.", "success");
      fetchData(currentPage, query, perPage, language_name);
    } catch (error) {
      swal.fire({
        title: "Error",
        text: "Failed to update switch status.",
        icon: "error",
      });
    }
  };

  const handleGoBack = () => {
    window.history.back();
  };

  const Showalert = () => {
    alert("Please Update your question audio!");
  };

  return (
    <Layout>
      <div className="page-content">

        <div className="row">
          <div className="col-md-12 col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body mt-0">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="card-title add-bulk">Question List</div>
                  <button className="btn btn-primary mb-1" onClick={handleGoBack}>
                    Back
                  </button>
                </div>
                <div className="col-sm-12 col-md-12">
                  <Link to="/add-questions" className="btn btn-primary btn-icon-text float-end mx-2">
                    {/* <Icon.Plus className="btn-icon-prepend" /> */}
                    Add Bulk Questions
                  </Link>
                </div>
                <div className="col-sm-12 col-md-12 mt-2"> {/* Added mt-2 for margin top */}
                  <Link to="/add-question" className="btn btn-primary btn-icon-text float-end">
                    {/* <Icon.Plus className="btn-icon-prepend" /> */}
                    Add Question
                  </Link>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-5">
                    <label className="form-check-label mx-1">Show</label>
                    <select className="form-select form-select-sm w-25 d-sm-inline-block mx-2" name="perpage" onChange={handlePerPageChange}>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                    </select>
                    {/* <label className="form-check-label">Entries</label> */}
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="dataTables_paginate paging_simple_numbers" id="dataTableExample_paginate">
                      <input value={query} onChange={handleSearch} type="search" className="form-control" placeholder="Search by question title...." aria-controls="dataTableExample" />
                    </div>
                  </div>
                  <div className="language-filter col-sm-12 col-md-3" style={{ marginTop: "-3px" }}>
                    <span className="fs-3 p-0"><AiOutlineFilter /></span>
                    <select
                      className="language-list border-btn-primary border-primary mb-1" style={{ borderRadius: "5px", padding: "7.5px" }}
                      onChange={(e) => handleLanguageSelect(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Select Language
                      </option>
                      {languages.map((language) => (
                        <option key={language.id} value={language.id}>
                          {language.language_name}
                        </option>
                      ))}
                    </select>

                  </div>
                </div>
                <div className="table-responsive">
                  {loading ? (
                    <div className="data_loader">
                      <PuffLoader color="#6571ff" />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <br />
                  <table className="table table-hover">

                    <thead>
                      <tr>
                        <th>#</th>
                        <th>VIP</th>
                        <th>Question Title</th>
                        <th>questionID</th>
                        <th>Language</th>
                        <th>Category</th>
                        <th>Status</th>
                        <th>Action</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {wordData.map((questions, i) => {
                        return (
                          <tr key={i} style={{ backgroundColor: questions.mocktest_status === 1 ? '#d7bf89' : 'inherit' }}>
                            <th>{questions?.serial_no}</th>
                            <td>
                              <input
                                type="checkbox"
                                checked={questions.vip_question === 1}
                                onChange={() => handleSwitchVIP(questions.id)}
                                style={{ cursor: "pointer" }}
                              />
                            </td>
                            <td>{questions.question_title}</td>
                            <td>{questions.id}</td>
                            <td>{questions.language.language_name}</td>
                            <td>{questions.category.category_name}</td>
                            <td className=" form-switch" >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={questions.status === 1}
                                onChange={() => handleSwitchToggle(questions.id)}
                                style={{ cursor: "pointer" }}
                              />
                            </td>
                            <td style={{padding:"0px"}}>
                              {/* <Link className="btn" to={`/users-view/${questions.id}`}>
                                <Icon.Eye className="link-icon" />
                              </Link> */}
                              <Link className="btn" to={`/question-edit/${questions.id}`}>
                                <Icon.Edit className="link-icon" />
                              </Link>
                              <button onClick={() => handleDelete(questions.id)} className="btn">
                                <Icon.Trash className="link-icon" />
                              </button>
                            </td>
                            <td> <div class="content">
                              <label class="switch m5">
                                <input type="checkbox"
                                  checked={questions.free_status === 0}
                                  onChange={() => handleFreeStatusChange(questions.id)}
                                />
                                <small></small>
                              </label>
                            </div></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-7">
                    <div className="dataTables_info" id="dataTableExample_info" role="status" aria-live="polite">
                      {showTotal}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-5">
                    <div className="dataTables_paginate paging_simple_numbers" id="dataTableExample_paginate">
                      <ResponsivePagination current={currentPage} total={totalPages} onPageChange={handlePageClick} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
