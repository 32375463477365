
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import swal from "sweetalert2";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import PuffLoader from "react-spinners/PuffLoader";

export default function MockTestAnalysis() {
    const [wordData, setwordData] = useState([]);
    const [showTotal, setTotalShow] = useState([]);
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setperPage] = useState(10);
    const [totalPages, setotalPages] = useState(0);
    const [neww, setNeww] = useState(false);

    useEffect(() => {
        fetchData(currentPage, query, perPage);
    }, [query, neww]);

    const fetchData = async (currentPage, query = "", perPage = "") => {
        const url = `/api/v1/adm/mocktest-record-list/?search=${query}&page=${currentPage}&perpage=${perPage}`;
        try {
            const api = await axios(url);
            setLoading(false);
            // console.log(api.data.results);
            setwordData(api?.data?.results?.data);
            setCurrentPage(currentPage);
            setotalPages(api.data.results.last_page);
            setTotalShow("Showing " + api.data.results.from + " to " + api.data.results.to + " of " + api.data.results.total + " entries");
        } catch (err) {
            setLoading(false);
            console.log("something went wrong!");
        }
    };

    const handlePageClick = async data => {
        let currentPagex = data;
        // console.log(currentPagex);
        setLoading(true);
        // setperPage(10);
        fetchData(currentPagex, query, perPage);
    };

    const handleSearch = event => {
        setQuery(event.target.value);
        setLoading(true);
        // fetchData(0, event.target.value);
    };

    const handlePerPageChange = event => {
        setLoading(true);
        let perpage = event.target.value;
        setperPage(perpage);
        console.log(perpage);
        fetchData("", query, perpage);
    };
    const deleteItem = async id => {
        console.log(id);
        try {
            const api = await axios.delete(`/api/v1/adm/mocktest-record-delete?record_id=${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });
            const newwordData = wordData.filter(item => {
                return item.id !== id;
            });
            // alert(api.data.message);
            swal.fire("Deleted!", "Mock test has been successfully Deleted.", "success");
            setwordData(newwordData);
            setNeww(true)
            fetchData(currentPage, query, perPage);
        } catch (err) {
            // console.log("something went wrong!");
            swal.fire({
                title: "Error deleting item",
                text: err.message,
                icon: "error",
            });
        }
    };
    const handleDelete = id => {
        swal
            .fire({
                title: "Are you sure?",
                text: "You won't be delete this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            })
            .then(result => {
                if (result.isConfirmed) {
                    deleteItem(id);
                }
            });
    };
    return (
        <Layout>
            <div className="page-content">
                <div className="row">
                    <div className="col-md-12 col-lg-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="card-title text-start">Mock Test Analysis</h6>
                                <div className="d-flex mt-3">
                                    <div className="col-sm-12 col-md-4 col-lg-4">
                                        <label className="form-check-label mx-1">Show</label>
                                        <select className="form-select form-select-sm w-25 d-sm-inline-block mx-2" name="perpage" onChange={handlePerPageChange}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                        </select>
                                        <label className="form-check-label"> entries</label>
                                    </div>
                                    <div className="col-sm-1 col-md-4 col-lg-4"></div>
                                    <div className="col-sm-12 col-md-4 col-lg-4">
                                        <div className="dataTables_paginate paging_simple_numbers" id="dataTableExample_paginate">
                                            <input value={query} onChange={handleSearch} type="search" className="form-control" placeholder="Search" aria-controls="dataTableExample" />
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    {loading ? (
                                        <div className="data_loader">
                                            <PuffLoader color="#6571ff" />
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Mock_test_name</th>
                                                <th>Test Date</th>
                                                <th>Student Id</th>
                                                <th>Name</th>
                                                <th>Email Id</th>
                                                <th>Review</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {wordData?.map((mocktest, i) => {
                                                const created_date = new Date(mocktest?.created_at);
                                                return (
                                                    <tr key={i}>
                                                        <th>{mocktest?.serial_no}</th>
                                                        <td>{mocktest.mocktest_name}</td>
                                                        <td>{created_date == "Invalid Date" ? "" : created_date.toLocaleString("en-IN", { month: "2-digit", day: "2-digit", year: "numeric" })}</td>
                                                        <td>{mocktest.user_id}</td>
                                                        <td>{mocktest.name + " " + mocktest.last_name}</td>
                                                        <td>{mocktest.email} </td>
                                                        <td>{mocktest.feedback_status === 1 ? "Submitted" : " Pending.."} </td>
                                                        <td>
                                                            <Link className="btn" to={`/mock-test/analysis/submit/${mocktest.mocktest_record_id}`}>
                                                                <Icon.Edit className="link-icon" />
                                                            </Link>
                                                            <button onClick={() => handleDelete(mocktest.mocktest_record_id)} className="btn">
                                                                <Icon.Trash className="link-icon" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-sm-12 col-md-7">
                                        <div className="dataTables_info" id="dataTableExample_info" role="status" aria-live="polite">
                                            {showTotal}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-5">
                                        <div className="dataTables_paginate paging_simple_numbers" id="dataTableExample_paginate">
                                            <ResponsivePagination current={currentPage} total={totalPages} onPageChange={handlePageClick} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
