import React, { useState, useEffect } from 'react'
import Layout from '../../components/Layout'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import swal from "sweetalert2";

const AddMockTest = () => {
    const [CategoryData, setCategoryData] = useState([]);
    const [LanguageData, setLanguageData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        mocktest_name: '',
        duration: '',
        catgeory_id: '',
        language_id: '',
    });

    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        console.log(formData)
        try {
            const response = await axios.post(`/api/v1/adm/mocktest-create`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setLoading(false);
            localStorage.setItem("mocktest_id" ,response?.data?.results?.mocktest_id);
            localStorage.setItem("language_id" ,response?.data?.results?.language_id);
            localStorage.setItem("language_name" ,response?.data?.results?.language_name);
            localStorage.setItem("catgeory_id" ,response?.data?.results?.catgeory_id);
            swal.fire("Success!", "Mock test has been successfully created.Proceed to add dialogus", "success");
            navigate("/add-mock-test/set");
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
            swal.fire({
                title: "Unable to Add item",
                text: error.message,
                icon: "error",
            });
        }
    };

    const FetchLanguage = () => {
        axios
            .get(`/api/v1/adm/languages`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            })
            .then(response => {
                //console.log(response.data.results);
                setLanguageData(response.data.results);
            })
            .catch(error => {
                console.log(error);
                swal.fire({
                    title: "Unable to fetch data - Languages",
                    text: error.message,
                    icon: "error",
                });
            });
    };

    const ListCategory = query => {
        axios
            .get(`/api/v1/adm/category?search=${query}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            })
            .then(response => {
                //console.log(response.data.results);
                setCategoryData(response.data.results);
            })
            .catch(error => {
                console.log(error);
                swal.fire({
                    title: "Unable to fetch data - Categories",
                    text: error.message,
                    icon: "error",
                });
            });
    };

    useEffect(() => {
        ListCategory();
        FetchLanguage();
    }, []);

    const handleGoBack = () => {
        window.history.back()
    };

    return (
        <Layout>
            <div className='page-content'>
                <div className="container mt-4 card p-4">
                    <div className='d-flex'>
                        <div className='col-md-6 col-lg-6 text-start'><h5>Add Mock Test</h5></div>
                        <div className='col-md-6 col-lg-6 text-end'><button className='btn btn-primary' onClick={handleGoBack}> Back</button></div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="question_title" className="form-label">
                                Mock Test Name
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="mocktest_name"
                                name="mocktest_name"
                                value={formData.mocktest_name}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="question_background" className="form-label">
                                Duration (in minutes)
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="duration"
                                name="duration"
                                value={formData.duration}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="language_id" className="form-label">
                                Language
                            </label>
                            <select
                                className="form-select"
                                value={formData.language_id}
                                onChange={handleChange}
                                placeholder="Please Select Language"
                                name="language_id"
                            >
                                <option value="" disabled>Select Language</option>
                                {LanguageData.map((language, i) => (
                                    <option key={i} value={language.id}>
                                        {language.language_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="catgeory_id" className="form-label">
                                Category
                            </label>
                            <select
                                className="form-select"
                                value={formData.catgeory_id}
                                onChange={handleChange}
                                placeholder="Please Select Language"
                                name="catgeory_id"
                            >
                                <option value="" disabled>Select Category</option>
                                {CategoryData.map((category, i) => (
                                    <option key={i} value={category.id}>
                                        {category.category_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {loading ? <button type="submit" className="btn btn-primary">
                            Loading...
                        </button> : <button type="submit" className="btn btn-primary">
                            Submit
                        </button>}
                    </form>
                </div>
            </div>
        </Layout>
    )
}

export default AddMockTest