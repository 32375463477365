import React from "react";
import Layout from "../../components/Layout";
import FormQuestion from "./FormQuestion";
const AddQuestion = () => {
  const handleGoBack = () => {
    window.history.back();
  };
  return (
    <>
      <Layout>
        <div className="page-content">
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="card-title add-bulk">
                      Add Question
                    </div>
                    <button className="btn btn-primary" onClick={handleGoBack}>
                      Back
                    </button>
                  </div>
                  <div className="col-sm-12 col-md-12"></div>
                  <FormQuestion />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AddQuestion;
