import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import Words from "./Words";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert2";

export default function WordListImport() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    category_id: "",
    language_id: "",
  });
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [CategoryData, setCategoryData] = useState([]);
  const [LanguageData, setLanguageData] = useState([]);

  useEffect(() => {
    ListCategory("");
    FetchLanguage();
  }, []);

  const ListCategory = query => {
    axios
      .get(`/api/v1/adm/category?search=${query}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(response => {

        setCategoryData(response.data.results);
      })
      .catch(error => {
        console.log(error);
        swal.fire({
          title: "Unable to fetch data - Categories",
          text: error.message,
          icon: "error",
        });
      });
  };

  const FetchLanguage = () => {
    axios
      .get(`/api/v1/adm/languages`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(response => {
        setLanguageData(response.data.results);
      })
      .catch(error => {
        console.log(error);
        swal.fire({
          title: "Unable to fetch data - Languages",
          text: error.message,
          icon: "error",
        });
      });
  };

  const [loading, setLoading] = useState();

  console.log("12345", formData?.language_id)
  console.log("12345", formData?.category_id)

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    const filesArray = Array.from(selectedFiles);
    setFiles(filesArray[0]); // Use setFiles to update the state
  };

  const onSubmitChange = async e => {
    e.preventDefault();
    const formDatas = new FormData();

    formDatas.append(`import_file`, files);
    formDatas.append('language_id', formData?.language_id);
    formDatas.append('category_id', formData?.category_id);
    try {
      const responce = await axios.post("/api/v1/adm/word-import", formDatas, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      swal.fire("Success!", "Word has been imports successfully.", "success");
      navigate("/words");
    } catch (error) {
      console.log(error.response.status);
      console.log("Something Wrong");
      if (error.response.status == 422) {
        setErrors(error.response.data.errors);
      } else {
        swal.fire({
          title: "Unable to Add item",
          text: error.message,
          icon: "error",
        });
      }
    }
  };

  if (loading) {
    return <Words />;
  }
  const handleChange = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const ClicktoBack = () => {
    navigate("/words");
  };

  return (
    <Layout>
      <div className="page-content">
  
        <div className="row">
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">Imports New Word</h6>
                <form onSubmit={e => onSubmitChange(e)} className="forms-sample">
                  <div className="mb-3">
                    <label htmlFor="exampleInputPassword1" className="form-label">
                      Category
                    </label>
                    <select onChange={handleChange} name="category_id" className="form-control form-select" id="exampleInputPassword1" placeholder="Category">
                      <option>Select Category</option>
                      {CategoryData.map((category, i) => {
                        return <option value={category.id}>{category.category_name}</option>;
                      })}
                    </select>
                    {errors.category && <div className="text-danger mt-1">{errors.category[0]}</div>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputL" className="form-label">
                      Language
                    </label>
                    <select onChange={handleChange} name="language_id" className="form-control form-select" id="exampleInputL" placeholder="Language">
                      <option>Select Language</option>
                      {LanguageData.map((language, x) => {
                        return <option value={language.id}>{language.language_name}</option>;
                      })}
                    </select>
                    {errors.language && <div className="text-danger mt-1">{errors.language[0]}</div>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="file" className="form-label">
                      Choose word File
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="file"
                      multiple
                      onChange={handleFileChange}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary me-2">
                    Submit
                  </button>
                  <button className="btn btn-secondary" onClick={ClicktoBack}>
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
