import React, { useState } from 'react';
import Layout from '../../components/Layout';
import axios from 'axios';
import swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const AddPlan = () => {
    const [formData, setFormData] = useState({
        plan_name: '',
        price: '',
        mocktest_limits: '',
        // mocktest_without_score:'',
        practice_limits: '',
        module_name: '',
        exam_material_limits: '',
        realtime_vip_material: '',
        is_topup_plan: '',
        is_subscription: '',
        stripe_id: '',
        status: '',
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/v1/adm/plan-add', formData);
            console.log(response.data);
            swal.fire("Success!", "Plan successfully add.", "success");
            // Handle success or redirect
            navigate("/plan");
        } catch (error) {
            console.error('Error submitting form:', error);
            swal.fire("Error!", "Plan successfully add.", "error");
            // Handle error
        }
    };
    const handleGoBack = () => {
        window.history.back()
    };

    return (
        <Layout>
            <div className='page-content'>
                <div className="container mt-0">
                    <div className="card p-5 ">
                        <div className='d-flex'>
                            <div className='col-md-6 col-lg-6 text-start'><h5>Add Plan</h5></div>
                            <div className='col-md-6 col-lg-6 text-end'><button className='btn btn-primary' onClick={handleGoBack}> Back</button></div>
                        </div>
                        <span id="student_message" />
                        <br />
                        <form
                            id="adduser_form"
                            onSubmit={handleSubmit}
                        >
                            <input
                                type="hidden"
                                name="_token"
                                defaultValue="CLpr1T6PMLaic83H39gC58FLs4oQiW0QTBBB9rFU"
                            />
                            <input type="hidden" name="user_id" defaultValue="" />
                            <div className="row g-4">
                                <div className="col-md-6">
                                    <label className="form-label">
                                        Plan Name <span style={{ color: "red" }}>*</span>:
                                    </label>
                                    <input
                                        type="text"
                                        id="plan_name"
                                        required
                                        name="plan_name"
                                        className="form-control"
                                        value={formData.plan_name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">
                                        Module Name:
                                    </label>
                                    <input
                                        type="text"

                                        id="module_name"
                                        name="module_name"
                                        className="form-control"
                                        value={formData.module_name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">
                                        Price <span style={{ color: "red" }}>*</span>:
                                    </label>
                                    <input
                                        type="number"
                                        id="price"
                                        required
                                        name="price"
                                        className="form-control"
                                        value={formData.price}
                                        onChange={handleChange}
                                    />
                                </div>
                                {/* <div className="col-md-6">
                                    <label className="form-label">
                                        Mock Test Without Score.<span style={{ color: "red" }}>*</span>:
                                    </label>
                                    <input
                                        type="number"
                                        required
                                        id="mocktest_without_score"
                                        name="mocktest_without_score"
                                        className="form-control"
                                        value={formData.mocktest_without_score}
                                        onChange={handleChange}
                                    />
                                </div> */}
                                <div className="col-md-6">
                                    <label className="form-label">
                                        VIP Material.<span style={{ color: "red" }}>*</span>:
                                    </label>
                                    <input
                                        type="number"
                                        required
                                        id="realtime_vip_material"
                                        name="realtime_vip_material"
                                        className="form-control"
                                        value={formData.realtime_vip_material}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">
                                        Mock Test With Score.<span style={{ color: "red" }}>*</span>:
                                    </label>
                                    <input
                                        type="number"
                                        required
                                        id="mocktest_limits"
                                        name="mocktest_limits"
                                        className="form-control"
                                        value={formData.mocktest_limits}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">
                                        Practice Limits<span style={{ color: "red" }}>*</span>:
                                    </label>
                                    <input
                                        type="number"
                                        required
                                        id="practice_limits"
                                        name="practice_limits"
                                        className="form-control"
                                        value={formData.practice_limits}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">
                                        Exam Material Limits.<span style={{ color: "red" }}>*</span>:
                                    </label>
                                    <input
                                        type="number"
                                        required
                                        id="exam_material_limits"
                                        name="exam_material_limits"
                                        className="form-control"
                                        value={formData.exam_material_limits}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="Role" className="form-label">
                                        Is Topup Plan<span style={{ color: "red" }}>*</span>:
                                    </label>
                                    <select
                                        required
                                        name="is_topup_plan"
                                        className="form-select"
                                        value={formData.is_topup_plan}
                                        onChange={handleChange}
                                    >
                                        <option value="">select</option>
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="Role" className="form-label">
                                        Plan Status<span style={{ color: "red" }}>*</span>:
                                    </label>
                                    <select
                                        required
                                        name="status"
                                        className="form-select"
                                        value={formData.status}
                                        onChange={handleChange}
                                    >
                                        <option value="">select</option>
                                        <option value={1}>enable</option>
                                        <option value={0}>disable</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="Role" className="form-label">
                                        Subscription Type<span style={{ color: "red" }}>*</span>:
                                    </label>
                                    <select
                                        required
                                        name="is_subscription"
                                        className="form-select"
                                        value={formData.is_subscription}
                                        onChange={handleChange}
                                    >
                                        <option value="">select</option>
                                        <option value={1}>Online</option>
                                        <option value={0}>Offline</option>
                                    </select>
                                </div>

                                {formData.is_subscription === '1' && (
                                    <div className="col-md-6">
                                        <label className="form-label">
                                            Stripe Id<span style={{ color: "red" }}>*</span>:
                                        </label>
                                        <input
                                            type="text"
                                            id="stripe_id"
                                            name="stripe_id"
                                            className="form-control"
                                            value={formData.stripe_id}
                                            onChange={handleChange}
                                        />
                                    </div>
                                )}
                                <div className="col-md-12 mt-5">
                                    <button type="submit" className="btn btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default AddPlan;
