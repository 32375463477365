import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import Words from "./Words";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert2";

export default function WordAdd() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    word: "",
    paraphrase: "",
    category_id: "",
    language_id: "",
  });

  const [errors, setErrors] = useState({});
  const [CategoryData, setCategoryData] = useState([]);
  const [LanguageData, setLanguageData] = useState([]);

  useEffect(() => {
    ListCategory("");
    FetchLanguage();
  }, []);

  const ListCategory = query => {
    axios
      .get(`/api/v1/adm/category?search=${query}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(response => {
        //console.log(response.data.results);

        setCategoryData(response.data.results);
      })
      .catch(error => {
        console.log(error);
        swal.fire({
          title: "Unable to fetch data - Categories",
          text: error.message,
          icon: "error",
        });
      });
  };

  const FetchLanguage = () => {
    axios
      .get(`/api/v1/adm/languages`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(response => {
        //console.log(response.data.results);

        setLanguageData(response.data.results);
      })
      .catch(error => {
        console.log(error);
        swal.fire({
          title: "Unable to fetch data - Languages",
          text: error.message,
          icon: "error",
        });
      });
  };

  // const handleSubmit = event => {
  //   event.preventDefault();
  //   // Handle form submission here
  //   console.log(formData);
  //   //alert(formData.username);
  //   try {
  //     const responce= await axios.post("http://127.0.0.1:8000/api/addnew", formData);
  //     console.log(responce)
  //     setLoading(true);
  // } catch (err) {
  //     console.log("Something Wrong");
  // }
  // };

  const [loading, setLoading] = useState();
  const onSubmitChange = async e => {
    e.preventDefault();
    try {
      // const responce = axios.post(`/api/v1/adm/users/add`, formData, {
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      //     "Content-Type": "application/json",
      //   },
      //   withCredentials: true,
      // });

      const responce = await axios.post("/api/v1/adm/words", formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      console.log(responce);
      // alert("User successfully created!");
      swal.fire("Success!", "Word has been successfully created.", "success");
      navigate("/words");
      //setLoading(true);
    } catch (error) {
      console.log(error.response.status);
      console.log("Something Wrong");
      if (error.response.status == 422) {
        setErrors(error.response.data.errors);
      } else {
        swal.fire({
          title: "Unable to Add item",
          text: error.message,
          icon: "error",
        });
      }
    }
  };

  if (loading) {
    return <Words />;
  }
  const handleChange = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const ClicktoBack = () => {
    navigate("/words");
  };

  return (
    <Layout>
      <div className="page-content">

        <div className="row">
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">Add New Word</h6>

                <form onSubmit={e => onSubmitChange(e)} className="forms-sample">
                  <div className="mb-3">
                    <label htmlFor="exampleInputUsername1" className="form-label">
                      Word
                    </label>
                    <input
                      type="text"
                      name="word"
                      value={formData.word || ""}
                      onChange={handleChange}
                      className="form-control"
                      id="exampleInputUsername1"
                      autoComplete="off"
                      placeholder="Word"
                    />
                    {errors.word && <div className="text-danger mt-1">{errors.word[0]}</div>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Paraphrase
                    </label>
                    <input
                      type="text"
                      name="paraphrase"
                      value={formData.paraphrase || ""}
                      onChange={handleChange}
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Paraphrase"
                    />
                    {errors.paraphrase && <div className="text-danger mt-1">{errors.paraphrase[0]}</div>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputPassword1" className="form-label">
                      Category
                    </label>

                    <select onChange={handleChange} name="category_id" className="form-control form-select" id="exampleInputPassword1" placeholder="Category">
                      <option>Select Category</option>
                      {CategoryData.map((category, i) => {
                        return <option value={category.id}>{category.category_name}</option>;
                      })}
                    </select>
                    {errors.category && <div className="text-danger mt-1">{errors.category[0]}</div>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputL" className="form-label">
                      Language
                    </label>

                    <select onChange={handleChange} name="language_id" className="form-control form-select" id="exampleInputL" placeholder="Language">
                      <option>Select Language</option>
                      {LanguageData.map((language, x) => {
                        return <option value={language.id}>{language.language_name}</option>;
                      })}
                    </select>
                    {errors.language && <div className="text-danger mt-1">{errors.language[0]}</div>}
                  </div>
                  <button type="submit" className="btn btn-primary me-2">
                    Submit
                  </button>
                  <button className="btn btn-secondary" onClick={ClicktoBack}>
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
