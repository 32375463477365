
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import swal from "sweetalert2";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import PuffLoader from "react-spinners/PuffLoader";

export default function ManageUserAccess() {
  const [selectedPlan, setSelectedPlan] = useState('');
  const [wordData, setwordData] = useState([]);
  const [showTotal, setTotalShow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setperPage] = useState(20);
  const [totalPages, setotalPages] = useState(0);
  const [neww, setNeww] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    fetchData(currentPage, query, perPage);
  }, [query, neww, selectedPlan]);

  const fetchData = async (currentPage, query = "", perPage = "") => {
    const url = `/api/v1/adm/user-plan-sub-list/?email_name=${query}&plan_id=${selectedPlan}&page=${currentPage}&perpage=${perPage}`;
    try {
      const api = await axios(url);
      setLoading(false);
      // console.log(api.data.results);
      setwordData(api?.data?.results?.data);
      setCurrentPage(currentPage);
      setotalPages(api.data.results.last_page);
      setTotalShow("Showing " + api.data.results.from + " to " + api.data.results.to + " of " + api.data.results.total + " entries");
    } catch (err) {
      setLoading(false);
      console.log("something went wrong!");
    }
  };

  const fetchDataPlan = async (query = "") => {
    const url = `/api/v1/adm/user-access-plan-list`;
    try {
      const api = await axios(url);
      setPlans(api.data.results)
    } catch (err) {
      console.log("something went wrong!");
    }
  };

  useEffect(() => {
    fetchDataPlan();
  }, []);

  const resetData = () => {
    setSelectedPlan("");
    setQuery("");
  }

  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  const handlePageClick = async data => {
    let currentPagex = data;
    // console.log(currentPagex);
    setLoading(true);
    // setperPage(10);
    fetchData(currentPagex, query, perPage);
  };

  const handleSearch = event => {
    setQuery(event.target.value);
    setLoading(true);
    // fetchData(0, event.target.value);
  };

  const handlePerPageChange = event => {
    setLoading(true);
    let perpage = event.target.value;
    setperPage(perpage);
    console.log(perpage);
    fetchData("", query, perpage);
  };

  const deleteItem = async id => {
    try {
      const api = await axios.delete(`/api/v1/adm/user-plan-sub-delete?subscribe_id=${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      const newwordData = wordData.filter(item => {
        return item.id !== id;
      });
      // alert(api.data.message);
      swal.fire("Deleted!", "Mock test has been successfully Deleted.", "success");
      setwordData(newwordData);
      setNeww(true)
    } catch (err) {
      // console.log("something went wrong!");
      swal.fire({
        title: "Error deleting item",
        text: err.message,
        icon: "error",
      });
    }
  };
  const handleDelete = id => {
    swal
      .fire({
        title: "Are you sure?",
        text: "You won't be delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      .then(result => {
        if (result.isConfirmed) {
          deleteItem(id);
        }
      });
  };
  const handleSwitchToggle = async (id) => {
    try {
      await axios.put(`/api/v1/adm/user-plan-sub-status?subscribe_id=${id}`);
      swal.fire("Updated!", "Status has been updated successfully.", "success");
      fetchData(currentPage, query, perPage);
    } catch (error) {
      // Handle errors, show an error message, etc.
      console.error("Error updating switch status:", error);
      swal.fire({
        title: "Error",
        text: "Failed to update switch status.",
        icon: "error",
      });
    }
  };

  return (
    <Layout>
      <div className="page-content">
        <div className="row">
          <div className="col-md-12 col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="col-md-10 col-lg-10 card-title text-start">User Plan Assign List</h6>
                  <Link to="/assign-new/user">
                    <button className="btn btn-primary">
                      Add New
                    </button>
                  </Link>
                  <button className="btn btn-primary" onClick={() => setShowFilter(!showFilter)}>
                    Filter
                  </button>
                </div>
                {showFilter && (
                  <div className="d-flex mt-4">
                    <label className="p-2">Student Email/Name</label>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      <div className="dataTables_paginate paging_simple_numbers" id="dataTableExample_paginate">
                        <input
                          value={query}
                          onChange={handleSearch}
                          type="search"
                          className="form-control"
                          placeholder="Search"
                          aria-controls="dataTableExample"
                        />
                      </div>
                    </div>
                    <label htmlFor="plan_id" className="form-label p-1">
                      Search By Plan <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="col-lg-4 col-md-4">
                      <div>
                        <div style={{ position: 'relative' }}>
                          <select
                            value={selectedPlan}
                            onChange={handlePlanChange}
                            className="form-control"
                            required
                          >
                            <option value="" disabled>Select Plan</option>
                            {plans.map((plan) => (
                              <option key={plan.id} value={plan.id}>{plan.plan_name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* <div className=" col-lg-1 col-md-1"><button className="btn btn-primary">Search</button></div> */}
                    <div className="col-lg-1 col-md-1"><button className="btn btn-primary" onClick={resetData}>Reset</button></div>
                  </div>
                )}
                <div className="d-flex mt-3">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <label className="form-check-label mx-1">Show</label>
                    <select className="form-select form-select-sm w-25 d-sm-inline-block mx-2" name="perpage" onChange={handlePerPageChange}>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                    </select>
                    <label className="form-check-label"> entries</label>
                  </div>
                  <div className="col-sm-1 col-md-4 col-lg-4"></div>
                </div>
                <div className="table-responsive">
                  {loading ? (
                    <div className="data_loader">
                      <PuffLoader color="#6571ff" />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Student</th>
                        <th>Email</th>
                        <th>Plan</th>
                        <th>Expiry In</th>
                        <th>Mocktest Limit</th>
                        <th>Created</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {wordData.map((mocktest, i) => {
                        const created_date = new Date(mocktest?.created_at);
                        const created_dates = new Date(mocktest?.expiry_date);
                        return (
                          <tr key={i}>
                            <th>{mocktest?.serial_no}</th>
                            <td>{mocktest.name}</td>
                            <td>{mocktest.email}</td>
                            <td>{mocktest.plan_name}</td>
                            <td>
                              {mocktest.expiry_in_days === 0 ? "Today" : mocktest.expiry_in_days > "0" ? `${mocktest.expiry_in_days} Days` : <span style={{ color: "red" }}>Expired</span>}<br />
                              {created_dates === "Invalid Date" ? "" : created_dates.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" })}
                            </td>
                            <td>{mocktest.mocktest_limits}</td>
                            <td>{created_date == "Invalid Date" ? "" : created_date.toLocaleString("en-IN", { month: "2-digit", day: "2-digit", year: "numeric" })}</td>
                            <td className=" form-switch" data-toggle="tooltip" data-placement="top" title="Change Status">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={mocktest.status === "1"}
                                onChange={() => handleSwitchToggle(mocktest.id)}
                                style={{ cursor: "pointer" }}
                              /></td>
                            <td >
                              <Link className="btn" to={`/add-topup/${mocktest.id}`} style={{ marginTop: "-10px" }}>
                                <span data-toggle="tooltip" data-placement="top" title="Topup Plan">
                                  <Icon.PlusCircle className="link-icon" /></span>
                              </Link>
                              {mocktest.subscription_type === "offline" ? <>
                                <Link className="btn" to={`/user-update-plan/access/${mocktest.id}`} style={{ marginTop: "-10px" }}>
                                  <span data-toggle="tooltip" data-placement="top" title="Edit Question">
                                    <Icon.Edit className="link-icon" /></span>
                                </Link>
                                <button onClick={() => handleDelete(mocktest.id)} className="btn " style={{ marginTop: "-10px" }} data-toggle="tooltip" data-placement="top" title="Delete Question">
                                  <Icon.Trash className="link-icon" />
                                </button></> : <button className="btn btn-success" style={{ color: "white" }}>Online</button>
                              }
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-7">
                    <div className="dataTables_info" id="dataTableExample_info" role="status" aria-live="polite">
                      {showTotal}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-5">
                    <div className="dataTables_paginate paging_simple_numbers" id="dataTableExample_paginate">
                      <ResponsivePagination current={currentPage} total={totalPages} onPageChange={handlePageClick} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
