
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import swal from "sweetalert2";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import PuffLoader from "react-spinners/PuffLoader";

export default function Plan() {
    const [wordData, setwordData] = useState([]);
    const [showTotal, setTotalShow] = useState([]);
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setperPage] = useState(10);
    const [totalPages, setotalPages] = useState(0);
    const [neww, setNeww] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [formData, setFormData] = useState("");

    useEffect(() => {
        fetchData(currentPage, query, perPage);
    }, [query, neww]);

    const fetchData = async (currentPage, query = "", perPage = "") => {
        const url = `/api/v1/adm/user-plan-list/?search=${query}&page=${currentPage}&perpage=${perPage}`;
        try {
            const api = await axios(url);
            setLoading(false);
            // console.log(api.data.results);
            setwordData(api?.data?.results?.data);
            setCurrentPage(currentPage);
            setotalPages(api.data.results.last_page);
            setTotalShow("Showing " + api.data.results.from + " to " + api.data.results.to + " of " + api.data.results.total + " entries");
        } catch (err) {
            setLoading(false);
            console.log("something went wrong!");
        }
    };

    const fetchDataDetail = async (selectedItemId) => {
        const url = `/api/v1/adm/plan-details?id=${selectedItemId}`;
        try {
            const api = await axios(url);
            setFormData(api.data.results);
        } catch (err) {
            console.log("something went wrong!");
        }
    };

    useEffect(() => {
        fetchDataDetail(selectedItemId);
    }, [selectedItemId])

    console.log("formData", formData)

    const handlePageClick = async data => {
        let currentPagex = data;
        // console.log(currentPagex);
        setLoading(true);
        // setperPage(10);
        fetchData(currentPagex, query, perPage);
    };

    const handleSearch = event => {
        setQuery(event.target.value);
        setLoading(true);
        // fetchData(0, event.target.value);
    };

    const handlePerPageChange = event => {
        setLoading(true);
        let perpage = event.target.value;
        setperPage(perpage);
        console.log(perpage);
        fetchData("", query, perpage);
    };

    const deleteItem = async id => {
        console.log(id);
        try {
            const api = await axios.delete(`/api/v1/adm/plan-delete?plan_id=${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });
            const newwordData = wordData.filter(item => {
                return item.id !== id;
            });
            // alert(api.data.message);
            swal.fire("Deleted!", "Plan has been successfully Deleted.", "success");
            setwordData(newwordData);
            setNeww(true)
        } catch (err) {
            // console.log("something went wrong!");
            swal.fire({
                title: "Error deleting item",
                text: err.message,
                icon: "error",
            });
        }
    };

    const handleDelete = id => {
        swal
            .fire({
                title: "Are you sure?",
                text: "You won't be delete this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            })
            .then(result => {
                if (result.isConfirmed) {
                    deleteItem(id);
                }
            });
    };
    const handleOpenModal = (id) => {
        setSelectedItemId(id);
        setPopupOpen(true);
    };
    // Function to close Bootstrap modal
    const handleCloseModal = () => {
        setSelectedItemId(null);
        setPopupOpen(false);
    };
    return (
        <Layout>
            <div className="page-content">
                <div className="row">
                    <div className="col-md-12 col-lg-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="col-md-10 col-lg-10 card-title text-start">Plan List</h6>
                                    <Link to="/add-plan">
                                        <button className="btn btn-primary">
                                            Add Plan
                                        </button>
                                    </Link>
                                    {/* <button className="btn btn-primary">
                   Filter
                  </button> */}
                                </div>
                                <div className="d-flex mt-3">
                                    <div className="col-sm-12 col-md-4 col-lg-4">
                                        <label className="form-check-label mx-1">Show</label>
                                        <select className="form-select form-select-sm w-25 d-sm-inline-block mx-2" name="perpage" onChange={handlePerPageChange}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                        </select>
                                        <label className="form-check-label"> entries</label>
                                    </div>
                                    <div className="col-sm-1 col-md-4 col-lg-4"></div>
                                    <div className="col-sm-12 col-md-4 col-lg-4">
                                        <div className="dataTables_paginate paging_simple_numbers" id="dataTableExample_paginate">
                                            <input value={query} onChange={handleSearch} type="search" className="form-control" placeholder="Search" aria-controls="dataTableExample" />
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    {loading ? (
                                        <div className="data_loader">
                                            <PuffLoader color="#6571ff" />
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Plan Name</th>
                                                <th>Price</th>
                                                <th>Is Topup Plan</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {wordData.map((mocktest, i) => {
                                                const created_date = new Date(mocktest?.created_at);
                                                const created_dates = new Date(mocktest?.expiry_date);
                                                return (
                                                    <tr key={i}>
                                                        <th>{mocktest?.serial_no}</th>
                                                        <td>{mocktest.plan_name}</td>
                                                        <td>{mocktest.price}</td>
                                                        <td>{mocktest.is_topup_plan === 0 ? "NO" : "Yes"}</td>
                                                        <td>{mocktest.status === "0" ? <span style={{ color: "red" }}>Inactive</span> : <span style={{ color: "blue" }}>Active</span>}</td>
                                                        <td>
                                                        
                                                            <button
                                                                className="btn"
                                                                onClick={() => handleOpenModal(mocktest.id)}
                                                            >
                                                                <Icon.Eye className="link-icon" />
                                                            </button>
                                                            {mocktest.subscription_type === "0" ? <>
                                                            <Link className="btn" to={`/update-plan/${mocktest.id}`}>
                                                                <Icon.Edit className="link-icon" />
                                                            </Link></>:""}
                                                            {mocktest.subscription_type === "0" ? <>
                                                                <button onClick={() => handleDelete(mocktest.id)} className="btn">
                                                                    <Icon.Trash className="link-icon" />
                                                                </button></> : <button className="btn btn-success" style={{ color: "white" }}>Online</button>
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                {selectedItemId && (
                                    <div
                                        className={`modal fade ${isPopupOpen ? "show" : ""}`}
                                        tabIndex="-1"
                                        aria-hidden={!isPopupOpen}
                                        style={{ display: isPopupOpen ? "block" : "none" }}
                                    >
                                        <div className="modal-dialog ">
                                            <div className="modal-content bg-light">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">
                                                        Plan ID: {selectedItemId}
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="btn-close"
                                                        onClick={handleCloseModal}
                                                        aria-label="Close"
                                                    ></button>
                                                </div>
                                                <div className="modal-body">
                                                    {/* Additional content can be added here */}

                                                    <div className="d-flex">
                                                        <div className="col-md-6 col-lg-6">
                                                            <span className="text-primary fw-bold fs-5 p-2">Plan Name:</span>{" "}
                                                            <span className="fw-bold fs-5">{formData.plan_name}</span> <br />
                                                            <br />
                                                            <span className="text-primary fw-bold fs-5 p-2">Price:</span>{" "}
                                                            <span className="fw-bold fs-5">{formData.price}</span> <br />
                                                            <br />
                                                            <span className="text-primary fw-bold fs-5 p-2">Module Name:</span>{" "}
                                                            <span className="fw-bold fs-5">{formData.module_name}</span> <br />
                                                            <br />
                                                            <span className="text-primary fw-bold fs-5 p-2">Mocktest Limits:</span>{" "}
                                                            <span className="fw-bold fs-5">{formData.mocktest_limits}</span> <br />
                                                            <br />
                                                            <span className="text-primary fw-bold fs-5 p-2  ">Exam Material Limits:</span>{" "}
                                                            <span className="fw-bold fs-5">{formData.exam_material_limits}</span> <br />
                                                            <br />
                                                            <span className="text-primary fw-bold fs-5 p-2">Practice Limits:</span>{" "}
                                                            <span className="fw-bold fs-5">{formData.practice_limits}</span> <br />
                                                            <br />
                                                        </div>
                                                        <div className="col-md-6 col-lg-6">
                                                            <span className="text-primary fw-bold fs-5 p-2">Is Live Video Class:</span>
                                                            <span className="fw-bold fs-5">{formData.is_live_video_class === 0 ? "NO" : "Yes"}</span>
                                                            <br />
                                                            <br />
                                                            <span className="text-primary fw-bold fs-5 p-2">Is Template:</span>:{" "}
                                                            <span className="fw-bold fs-5">{formData.is_template === 0 ? "No" : "Yes"}</span>
                                                            <br />
                                                            <br />
                                                            <span className="text-primary fw-bold fs-5 p-2">Is Topup Plan:</span>:{" "}
                                                            <span className="fw-bold fs-5">{formData.is_topup_plan === 0 ? "NO" : "Yes"}</span>
                                                            <br />
                                                            <br />
                                                            <span className="text-primary fw-bold fs-5 p-2">AI Scoring:</span>:{" "}
                                                            <span className="fw-bold fs-5">{formData.ai_scoring}</span>
                                                            <br />
                                                            <br />
                                                            <span className="text-primary fw-bold fs-5 p-2">Prediction File:</span>:{" "}
                                                            <span className="fw-bold fs-5">{formData.prediction_file === 0 ? " NO" : "Yes"}</span>
                                                            <br />
                                                            <br />
                                                            <span className="text-primary fw-bold fs-5 p-2">Is Live Video Class:</span>:{" "}
                                                            <span className="fw-bold fs-5">{formData.is_live_video_class === 0 ? " NO" : "Yes"}</span>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        onClick={handleCloseModal}
                                                    >
                                                        Close
                                                    </button>
                                                    {/* Additional buttons can be added here */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="row mt-3">
                                    <div className="col-sm-12 col-md-7">
                                        <div className="dataTables_info" id="dataTableExample_info" role="status" aria-live="polite">
                                            {showTotal}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-5">
                                        <div className="dataTables_paginate paging_simple_numbers" id="dataTableExample_paginate">
                                            <ResponsivePagination current={currentPage} total={totalPages} onPageChange={handlePageClick} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
