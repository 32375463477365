import React, { useState, useEffect, Component } from "react";
import { Link, NavLink } from "react-router-dom";
import * as Icon from "react-feather";
// import ScrollBar from "react-perfect-scrollbar";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

export default function Navbar() {
  const [isToggled, setIsToggled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isSidebarFolded, setIsSidebarFolded] = useState(false);

  const toggleButtonClass = isToggled ? "sidebar-toggler" : "sidebar-toggler";
  // const toggleButtonClass = isToggled ? "sidebar-toggler active" : "sidebar-toggler not-active";
  const user_id = localStorage.getItem("user_id")
  const role = localStorage.getItem("role")

  const handleToggle = () => {
    setIsToggled(!isToggled);
    document.body.classList.toggle("sidebar-folded");
  };

  useEffect(() => {
    const body = document.body;
    const sidebar = document.getElementById("sidebar_t");
    const sidebarFolded = "sidebar-folded";

    if (body.classList.contains(sidebarFolded)) {
      setIsSidebarFolded(true);
      // sidebar.classList.add("not-active");
      // console.log("yes");
    } else {
      setIsSidebarFolded(false);
      // sidebar.classList.remove("active");
      // console.log("no");
    }
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
    document.body.classList.add("open-sidebar-folded");
    document.body.classList.add("overflow-hidden");
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    document.body.classList.remove("open-sidebar-folded");
    document.body.classList.remove("overflow-hidden");
  };

  return (
    <div>
      <nav className="sidebar">
        <div className="sidebar-header">
          <a href="#" className="sidebar-brand">
            Naati<span>CCL</span>
          </a>
          {/* "sidebar-toggler not-active */}
          <div id="sidebar_t" className={isSidebarFolded ? "sidebar-toggler active" : "sidebar-toggler not-active"} onClick={handleToggle}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <PerfectScrollbar component="div">
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="sidebar-body">
            <ul className="nav">
              {/* <li className="nav-item nav-category">Main</li> */}
              <li className="nav-item">
                <NavLink to="/dashboard" className="nav-link newNav" activeClassName="active">
                  {/* <Home  size={16} /> */}
                  <Icon.Home className="link-icon" />
                  <span className="link-title">Dashboard</span>
                </NavLink>
              </li>
              {/* <li className="nav-item nav-category">web apps</li>
              <li className="nav-item">
                <a className="nav-link newNav" data-bs-toggle="collapse" href="#emails" role="button" aria-expanded="false" aria-controls="emails">
                  <i className="link-icon" data-feather="mail"></i>
                  <span className="link-title">Email</span>
                  <Icon.ChevronDown className="link-arrow" />
                </a>
                <div className="collapse" id="emails">
                  <ul className="nav sub-menu">
                    <li className="nav-item">
                      <a href="../email/inbox.html" className="nav-link newNav">
                        Inbox
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="../email/read.html" className="nav-link newNav">
                        Read
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="../email/compose.html" className="nav-link newNav">
                        Compose
                      </a>
                    </li>
                  </ul>
                </div>
              </li> */}
              {/* <li className="nav-item">
                <Link to="/" className="nav-link newNav">
                  <Icon.Inbox className="link-icon" size={16} />

                  <span className="link-title">Blank Page</span>
                </Link>
              </li> */}
              {/* <li className="nav-item">
                <Link to="/form" className="nav-link newNav">
                  <Icon.Calendar className="link-icon" />
                  <span className="link-title">Form</span>
                </Link>
              </li> */}
              {role == "2" ? <>
                <li className="nav-item">
                <NavLink to="/mock-test/analysis" className="nav-link newNav" activeClassName="active">
                  <Icon.List className="link-icon" />
                  <span className="link-title">Mock Test Analysis</span>
                </NavLink>

              </li>
              <li className="nav-item">
                <NavLink to="/words" className="nav-link newNav" activeClassName="active">
                  <Icon.List className="link-icon" />
                  <span className="link-title">Word List</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/user-comments" className="nav-link newNav" activeClassName="active">
                  <Icon.List className="link-icon" />
                  <span className="link-title">User Comments</span>
                </NavLink>
              </li>
              </>:<>
              <li className="nav-item">
                <a className="nav-link newNav" data-bs-toggle="collapse" href="#naatiuser" role="button" aria-expanded="false" aria-controls="question">
                  <Icon.List className="link-icon" />
                  <span className="link-title">NAATI Manage Users</span>
                  <Icon.ChevronDown className="link-arrow" />
                </a>
                <div className="collapse" id="naatiuser">
                  <ul className="nav sub-menu">
                    <li className="nav-item">
                      <NavLink to="/users" className="nav-link newNav" activeClassName="active">
                        <Icon.Users className="link-icon" />
                        <span className="link-title">Users</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/add-users" className="nav-link newNav" activeClassName="active">
                        <Icon.Users className="link-icon" />
                        <span className="link-title">Add User</span>
                      </NavLink>
                    </li>
                    {/* Add more items as needed */}
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a className="nav-link newNav" data-bs-toggle="collapse" href="#pteuser" role="button" aria-expanded="false" aria-controls="questions">
                  <Icon.List className="link-icon" />
                  <span className="link-title">PTE Manage Users</span>
                  <Icon.ChevronDown className="link-arrow" />
                </a>
                <div className="collapse" id="pteuser">
                  <ul className="nav sub-menu">
                    <li className="nav-item">
                      <NavLink to="/users" className="nav-link newNav" activeClassName="active">
                        <Icon.Users className="link-icon" />
                        <span className="link-title">Users</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/pte-add-user" className="nav-link newNav" activeClassName="active">
                        <Icon.Users className="link-icon" />
                        <span className="link-title">Add User</span>
                      </NavLink>
                    </li>
                    {/* Add more items as needed */}
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <NavLink to="/words" className="nav-link newNav" activeClassName="active">
                  <Icon.List className="link-icon" />
                  <span className="link-title">Word List</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <a className="nav-link newNav" data-bs-toggle="collapse" href="#question" role="button" aria-expanded="false" aria-controls="question">
                  <Icon.List className="link-icon" />
                  <span className="link-title">Question Dialogues</span>
                  <Icon.ChevronDown className="link-arrow" />
                </a>
                <div className="collapse" id="question">
                  <ul className="nav sub-menu">
                    <li className="nav-item">
                      <NavLink to="/questions" className="nav-link newNav" activeClassName="active">
                        <span className="link-title">Question List</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/add-question" className="nav-link newNav" activeClassName="active">
                        <span className="link-title">Add question</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/add-questions" className="nav-link newNav" activeClassName="active">
                        <span className="link-title">Add bulk questions</span>
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink to="/add-audio/file" className="nav-link newNav" activeClassName="active">
                        <span className="link-title">Add Bulk Audio File</span>
                      </NavLink>
                    </li>
                    {/* Add more items as needed */}
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <NavLink to="/mock-test/list" className="nav-link newNav" activeClassName="active">
                  <Icon.List className="link-icon" />
                  <span className="link-title">Manage Mock Test</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/mock-test/analysis" className="nav-link newNav" activeClassName="active">
                  <Icon.List className="link-icon" />
                  <span className="link-title">Mock Test Analysis</span>
                </NavLink>
              </li>
              {user_id === "7" ? "" :
                <>
                  <li className="nav-item">
                    <NavLink to="/plan" className="nav-link newNav" activeClassName="active">
                      <Icon.List className="link-icon" />
                      <span className="link-title">Manage Plan</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/manage-user/access" className="nav-link newNav" activeClassName="active">
                      <Icon.List className="link-icon" />
                      <span className="link-title">Manage Users Access</span>
                    </NavLink>
                  </li></>}
              <li className="nav-item">
                <NavLink to="/user-comments" className="nav-link newNav" activeClassName="active">
                  <Icon.List className="link-icon" />
                  <span className="link-title">User Comments</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/video" className="nav-link newNav" activeClassName="active">
                  <Icon.List className="link-icon" />
                  <span className="link-title">Manage Video</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/enquire-list" className="nav-link newNav" activeClassName="active">
                  <Icon.List className="link-icon" />
                  <span className="link-title">Online Class Enquire</span>
                </NavLink>
              </li> </>}
              {/* <li className="nav-item nav-category">Docs</li>
              <li className="nav-item">
                <a href="https://www.nobleui.com/html/documentation/docs.html" target="_blank" className="nav-link newNav">
                  <i className="link-icon" data-feather="hash"></i>
                  <span className="link-title">Documentation</span>
                </a>
              </li> */}
            </ul>
          </div>
        </PerfectScrollbar>
      </nav>
    </div>
  );
}
