import React, { useState } from 'react';
import Dashboard from '../pages/Dashboard';
import PteDashboard from '../PteScreens/PteDashboard';
import Layout from './Layout';


const TabbedScreen = () => {
  const [activeTab, setActiveTab] = useState('screen1');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Layout>
      <div className="page-content">
      <div>
            <h4 className="mb-3 mb-md-0">Welcome To Dashboard</h4>
          </div>
        <div className="tabbed-screen-tabs">
          <button onClick={() => handleTabClick('screen1')} className={activeTab === 'screen1' ? 'active' : ''}>NAATI Dashboard</button>
          <button onClick={() => handleTabClick('screen2')} className={activeTab === 'screen2' ? 'active' : ''}>PTE Dashboard</button>
        </div>
        <div className="content">
          {activeTab === 'screen1' && <Dashboard />}
          {activeTab === 'screen2' && <PteDashboard />}
        </div>
      </div>
    </Layout>
  );
};

export default TabbedScreen;
