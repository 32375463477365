import React from "react";
import Layout from "../../components/Layout";
import UpdateQuestionForm from "./UpdateQuestionForm";

const EditQuestion = () => {

  return (
    <>
      <Layout>
        <div className="page-content">
          <nav className="page-breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                Edit Question
              </li>
              <li className="breadcrumb-item " aria-current="page"></li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="col-sm-12 col-md-12"></div>
                  <UpdateQuestionForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default EditQuestion;
