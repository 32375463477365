
import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert2';

const AddTopUp = () => {
    const [plans, setPlans] = useState([]);
    const [detail, setDetail] = useState("");
    const [selectedPlan, setSelectedPlan] = useState('');
    const [expiryDate, setExpiryDate] = useState('');

    const { id } = useParams();

    const handlePlanChange = (event) => {
        setSelectedPlan(event.target.value);
    };

    const handleExpiryDateChange = (event) => {
        setExpiryDate(event.target.value);
    };

    const fetchDataPlan = async () => {
        const url = `/api/v1/adm/topup-plan-list`;
        try {
            const api = await axios(url);
            setPlans(api.data.results);
        } catch (err) {
            console.log("something went wrong!");
        }
    };

    const fetchDataDetail = async () => {
        const url = `/api/v1/adm/user-details?subscribe_id=${id}`;
        try {
            const api = await axios(url);
            setDetail(api.data.results);
        } catch (err) {
            console.log("something went wrong!");
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = {
            topup_id: selectedPlan,
            subscibe_id: detail?.subscibe_id,
            expiry_date: expiryDate
        }

        try {
            // Replace the API endpoint with your actual endpoint
            const response = await axios.put('/api/v1/adm/add-topup', formData);
            swal.fire({
                title: "TopUp plan add successfully!",
                text: "success",
                icon: "success",
            });
        } catch (error) {
            // Handle errors, e.g., show an error message
            console.error('Error submitting form:', error);
        }
    };

    useEffect(() => {
        fetchDataPlan();
        fetchDataDetail();
    }, [id]);

    const handleGoBack = () => {
        window.history.back();
    };

    return (
        <Layout>
            <div className='page-content'>
                <div className="d-flex p-3 mb-0">
                    <h2 className="font-small mr-auto col-md-6 col-lg-6 text-start fs-4 mt-2">Add Topup Plan</h2>
                    <div className="col-lg-6 col-md-6 text-end">
                        <button className="btn btn-primary" onClick={handleGoBack}>
                            Back</button>
                    </div>
                </div>
                <div className="container mt-0">
                    <div className="card p-4">
                        <div className="overflow-x-auto mt-0">
                            <div className='text-start'>
                                <h5
                                    className="text-lg font-medium mr-auto"
                                    style={{ padding: 5, color: "darkblue" }}
                                >
                                    <span style={{ textDecoration: "underline" }}>Student Name :</span>{" "}
                                    {detail?.name + " " + detail?.last_name}
                                </h5>
                                <br />
                                <h5
                                    className="text-lg font-medium mr-auto"
                                    style={{ padding: 5, color: "darkblue" }}
                                >
                                    <span style={{ textDecoration: "underline" }}>Student Email :</span>{" "}
                                    {detail?.email}
                                </h5>
                                <br />
                                <h5
                                    className="text-lg font-medium mr-auto"
                                    style={{ padding: 5, color: "darkblue" }}
                                >
                                    <span style={{ textDecoration: "underline" }}>Current plan :</span>{" "}
                                    {detail?.plan_name}
                                </h5>
                                <br />
                                <h5
                                    className="text-lg font-medium mr-auto"
                                    style={{ padding: 5, color: "darkblue" }}
                                >
                                    <span style={{ textDecoration: "underline" }}>Plan Expiry Date :</span>{" "}
                                    {detail?.expiry_date}
                                </h5>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <input
                                    type="hidden"
                                    name="_token"
                                    defaultValue="wV1PtbcW38iYTM6ggoQkkDQ7XzYraij4JLusAZHw"
                                />
                                <input type="hidden" name="plan_id2" defaultValue={6} />
                                <input type="hidden" name="user_id" defaultValue={275} />
                                <input type="hidden" name="edit_id" defaultValue={2408} />
                                <input type="hidden" name="access_plan_id" defaultValue={2408} />
                                <div className="container p-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor="xzone_name" className="form-label">
                                                Select Topup Plan <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <div style={{ position: 'relative' }}>
                                                <select
                                                    value={selectedPlan}
                                                    onChange={handlePlanChange}
                                                    className="form-control"
                                                    required
                                                >
                                                    <option value="" disabled>Select Plan</option>
                                                    {plans?.map((plan) => (
                                                        <option key={plan.id} value={plan.id}>{plan.plan_name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="datetimepicker" className="form-label">
                                                Select Expiry Date <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                defaultValue=""
                                                name="expiry_date"
                                                value={expiryDate}
                                                onChange={handleExpiryDateChange}
                                            />
                                        </div>
                                        <div id="strert" className="col-md-12 mt-4" />
                                        <div className="col-md-12 mt-4">
                                            <button type="submit" className="btn btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AddTopUp