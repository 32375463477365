import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import { Link, useParams, useLocation } from "react-router-dom";
import * as Icon from "react-feather";
import swal from "sweetalert2";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import PuffLoader from "react-spinners/PuffLoader";

export default function MockTestSetList() {
    const [wordData, setwordData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [CategoryData, setCategoryData] = useState([]);
    const [showTotal, setTotalShow] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setperPage] = useState(10);
    const [totalPages, setotalPages] = useState(0);
    const [cat_id, setCat_id] = useState('');

    // const language_id = localStorage.getItem("language_id");
    // const language_name = localStorage.getItem("language_name");
    // const mocktest_id = localStorage.getItem("mocktest_id");
    const location = useLocation();
    const { id } = useParams();
    const queryParams = new URLSearchParams(location.search);

    // Extracting languageId and languageName from query parameters
    const languageId = queryParams.get("languageId");
    const languageName = queryParams.get("languageName");

    useEffect(() => {
        fetchData(currentPage, query, perPage);
    }, [id]);

    console.log("ashdf555555", languageName);

    const fetchData = async (currentPage, query = "", perPage = "") => {
        const url = `/api/v1/adm/question-selected?mock_id=${id}&language_id=${languageId}&category_id=${cat_id}&search=${query}&page=${currentPage}&perpage=${perPage}`;
        try {
            const api = await axios(url);
            setLoading(false);
            // console.log(api.data.results);
            setwordData(api.data.results.data);
            setCurrentPage(currentPage);
            setotalPages(api.data.results.last_page);
            setTotalShow("Showing " + api.data.results.from + " to " + api.data.results.to + " of " + api.data.results.total + " entries");
        } catch (err) {
            setLoading(false);
            console.log("something went wrong!");
        }
    };

    const handleItemSelect = (itemId) => {
        // Toggle the selected state of the item
        setSelectedItems(prevSelectedItems => {
            if (prevSelectedItems.includes(itemId)) {
                return prevSelectedItems.filter(id => id !== itemId);
            } else {
                return [...prevSelectedItems, itemId];
            }
        });
    };

    const handlePageClick = async data => {
        let currentPagex = data;
        // console.log(currentPagex);
        setLoading(true);
        // setperPage(10);
        fetchData(currentPagex, query, perPage);
    };

    const [query, setQuery] = useState("");

    const handleSearch = event => {
        setQuery(event.target.value);
        setLoading(true);
        fetchData(0, event.target.value);
    };

    // const handlePerPageChange = event => {
    //     setLoading(true);
    //     let perpage = event.target.value;
    //     setperPage(perpage);
    //     console.log(perpage);
    //     fetchData("", query, perpage);
    // };

    const searchUsers = query => {
        axios
            .get(`/api/v1/adm/questions?search=${query}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            })
            .then(response => {
                console.log(response.data.results);
                setwordData(response.data.results);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const deleteItem = async ids => {
        try {
            const api = await axios.delete(`/api/v1/adm/question-selected-remove?mocktest_id=${id}&question_id=${ids}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });
            const newwordData = wordData.filter(item => {
                return item.id !== id;
            });
            // alert(api.data.message);
            swal.fire("Deleted!", "word has been successfully Deleted.", "success");
            setwordData(newwordData);
        } catch (err) {
            // console.log("something went wrong!");
            swal.fire({
                title: "Error deleting item",
                text: err.message,
                icon: "error",
            });
        }
    };

    const handleDelete = id => {
        swal
            .fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            })
            .then(result => {
                if (result.isConfirmed) {
                    deleteItem(id);
                }
            });
    };

    const handleSearchBycat = () => {
        setLoading(true)
        if (languageId && cat_id) {
            fetchData(currentPage, query, perPage);
        }
    }

    useEffect(() => {
        ListCategory();
    }, []);

    const ListCategory = query => {
        axios
            .get(`/api/v1/adm/category?search=${query}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            })
            .then(response => {
                //console.log(response.data.results);
                setCategoryData(response.data.results);
            })
            .catch(error => {
                console.log(error);
                swal.fire({
                    title: "Unable to fetch data - Categories",
                    text: error.message,
                    icon: "error",
                });
            });
    };

    const handleSendData = () => {
        //  Send selected item IDs to your API
        const myArray = selectedItems;
        const arrayAsString = myArray.join(', ');
        const data = {
            mocktest_id: id,
            question_id: arrayAsString
        }
        axios.put('/api/v1/adm/mocktest-set-update', data)
            .then(response => {
                swal.fire("Success!", "Mock test set has been successfully created.", "success");
                setSelectedItems([]);
                //  navigate("/mock-test/list");
                //  console.log('Data sent successfully:', response.data);
            })
            .catch(error => {
                swal.fire("Warning!", "Mock test set not created! somthing went roungh.", "error");
                console.error('Error sending data:', error);
            });
    };

    console.log("selectedItems", selectedItems);

    return (
        <Layout>
            <div className="page-content">
                <nav className="page-breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            Question List
                        </li>
                        <li className="breadcrumb-item " aria-current="page"></li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-md-12 col-lg-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="card-title text-start">Manage Mock Test Question</h6>
                                <div className='d-flex p-2'>
                                    <div className='col-md-2 col-lg-2 mt-2 text-start'>
                                        Language : {languageName}
                                    </div>
                                    <div className='col-lg-2 col-md-2 mt-0 text-start'>
                                        <select
                                            className="form-select filter"
                                            value={cat_id}
                                            onChange={(e) => setCat_id(e.target.value)}
                                            placeholder="Please Select catagory"
                                            name="cat_id"
                                        >
                                            <option value="" disabled>Select Catagory</option>
                                            {CategoryData.map((Catagory, i) => (
                                                <option key={i} value={Catagory.id}>
                                                    {Catagory.category_name}
                                                </option>
                                            ))}
                                        </select>

                                    </div>
                                    <div className='col-md-4 col-lg-4'> <input value={query} onChange={handleSearch} type="search" className="form-control" placeholder="Search" aria-controls="dataTableExample" /></div>
                                    <div className='col-md-2 col-lg-2 text-center'> {languageId && cat_id && (
                                        <button className="btn btn-primary mt-0" onClick={handleSearchBycat}>
                                            Search
                                        </button>
                                    )}</div>
                                    <div className='col-md-2 col-lg-2 text-end'>
                                    </div>
                                </div>
                                {/* <div className="row mt-3">
                                    <div className="col-sm-12 col-md-8"></div>
                                    <div className="col-sm-12 col-md-4">
                                        <div className="dataTables_paginate paging_simple_numbers" id="dataTableExample_paginate">
                                            <input value={query} onChange={handleSearch} type="search" className="form-control" placeholder="Search" aria-controls="dataTableExample" />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="table-responsive">
                                    {loading ? (
                                        <div className="data_loader">
                                            <PuffLoader color="#6571ff" />
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                    {/* <div className="col-sm-12 col-md-4">
                                        <label className="form-check-label mx-1">Show</label>
                                        <select className="form-select form-select-sm w-25 d-sm-inline-block mx-2" name="perpage" onChange={handlePerPageChange}>
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                        </select>
                                        <label className="form-check-label"> entries</label>
                                    </div> */}
                                    <div className='col-lg-12 col-md-12 text-start'>{selectedItems.length > 0 && (
                                        <button className="btn btn-primary mt-0" onClick={handleSendData}>
                                            Create Mocktest
                                        </button>
                                    )}</div>
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th></th>
                                                <th>question</th>
                                                <th>question_background</th>
                                                <th>language_name</th>
                                                <th>category_name</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {wordData.map((questions, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <th>{i + 1}</th>
                                                        <td style={{ width: "0px" }}>
                                                            <input
                                                                type="checkbox"
                                                                checked={questions.status === 1 || selectedItems.includes(questions.id)}
                                                                onChange={() => handleItemSelect(questions.id)}
                                                                style={{ width: '15px', height: '15px', cursor: "pointer" }}
                                                            />
                                                        </td>
                                                        <td>{questions.question_title}</td>
                                                        <td>{questions.question_background}</td>
                                                        <td>{questions.language_name}</td>
                                                        <td>{questions.category_name}</td>
                                                        <td>
                                                            <button onClick={() => handleDelete(questions.id)} className="btn">
                                                                <Icon.Trash className="link-icon" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-sm-12 col-md-7">
                                        <div className="dataTables_info" id="dataTableExample_info" role="status" aria-live="polite">
                                            {showTotal}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-5">
                                        <div className="dataTables_paginate paging_simple_numbers" id="dataTableExample_paginate">
                                            <ResponsivePagination current={currentPage} total={totalPages} onPageChange={handlePageClick} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
