import React, { useState } from "react";
import Layout from "../../components/Layout";
import Users from "../Users";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert2";

export default function AddUsers() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  // const handleSubmit = event => {
  //   event.preventDefault();
  //   // Handle form submission here
  //   console.log(formData);
  //   //alert(formData.username);
  //   try {
  //     const responce= await axios.post("http://127.0.0.1:8000/api/addnew", formData);
  //     console.log(responce)
  //     setLoading(true);
  // } catch (err) {
  //     console.log("Something Wrong");
  // }
  // };
  const [loading, setLoading] = useState();
  const onSubmitChange = async e => {
    e.preventDefault();
    try {
      // const responce = axios.post(`/api/v1/users/add`, formData, {
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      //     "Content-Type": "application/json",
      //   },
      //   withCredentials: true,
      // });

      const responce = await axios.post("/api/v1/users/add", formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      console.log(responce);
      // alert("User successfully created!");
      swal.fire("Success!", "User has been successfully created.", "success");
      navigate("/users");
      //setLoading(true);
    } catch (err) {
      console.log("Something Wrong");
    }
  };

  if (loading) {
    return <Users />;
  }
  const handleChange = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const ClicktoBack = () => {
    navigate("/users");
  };

  return (
    <Layout>
      <div className="page-content">
        <div className="row">
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">Add User</h6>
                <form onSubmit={e => onSubmitChange(e)} className="forms-sample">
                  <div className="mb-3">
                    <label htmlFor="exampleInputUsername1" className="form-label">
                      Username
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name || ""}
                      onChange={handleChange}
                      className="form-control"
                      id="exampleInputUsername1"
                      autoComplete="off"
                      placeholder="Username"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email address
                    </label>
                    <input type="email" name="email" value={formData.email || ""} onChange={handleChange} className="form-control" id="exampleInputEmail1" placeholder="Email" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputPassword1" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      autoComplete="off"
                      placeholder="Password"
                      value={formData.password || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary me-2">
                    Submit
                  </button>
                  <button className="btn btn-secondary" onClick={ClicktoBack}>
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
