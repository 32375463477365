import React, { useEffect } from 'react';
import Highcharts from 'highcharts';

const Chart = () => {
  useEffect(() => {
    // Initialize Highcharts chart
    Highcharts.chart('container', {
      // chart: {
      //   type: 'line',
      //   width: 1000,
      //   height: 400
      // },
      title: {
        text: 'Monthly Predictive Registrations Analytics',
        style: {
          fontSize: '16px', // Main title font size
          fontWeight: 'bold' // Main title font weight
        }
      },
      subtitle: {
        text: 'Source: Vision Language Experts',
        style: {
          fontSize: '12px', // Subtitle font size
          fontStyle: 'italic' // Subtitle font style
        }
      },
      xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },
      yAxis: {
        title: {
          text: 'Vision language experts'
        }
      },
      series: [{
        name: 'Paid student',
        data: [177, 200, 310, 400, 500, 600, 700, 800, 810, 700, 600, 500]
      }, {
        name: 'Free student',
        data: [177, 200, 310, 400, 500, 600, 700, 800, 810, 700, 600, 500]
      }],
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
            formatter: function() {
              return this.y; // Display the y-value as the data label
            }
          }
        }
      },
      credits: {
        enabled: false
      }
    });
  }, []);

  return <div id="container" />;
};

export default Chart;
