import Navbar from "../components/Navbar";
import Topbar from "../components/Topbar";
import Footer from "../components/Footer";

export default function Form() {
  return (
    <div className="main-wrapper">
      <Navbar />
      <div className="page-wrapper">
        <Topbar />

        <div className="page-content">
          <nav className="page-breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                Forms
              </li>
              <li className="breadcrumb-item " aria-current="page">
                Basic Elements
              </li>
            </ol>
          </nav>

          <div className="row">
            <div className="col-md-6 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h6 className="card-title">Basic Form</h6>

                  <form className="forms-sample">
                    <div className="mb-3">
                      <label for="exampleInputUsername1" className="form-label">
                        Username
                      </label>
                      <input type="text" className="form-control" id="exampleInputUsername1" autocomplete="off" placeholder="Username" />
                    </div>
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        Email address
                      </label>
                      <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Email" />
                    </div>
                    <div className="mb-3">
                      <label for="exampleInputPassword1" className="form-label">
                        Password
                      </label>
                      <input type="password" className="form-control" id="exampleInputPassword1" autocomplete="off" placeholder="Password" />
                    </div>
                    <div className="form-check mb-3">
                      <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                      <label className="form-check-label" for="exampleCheck1">
                        Remember me
                      </label>
                    </div>
                    <button type="submit" className="btn btn-primary me-2">
                      Submit
                    </button>
                    <button className="btn btn-secondary">Cancel</button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h6 className="card-title">Horizontal Form</h6>

                  <form className="forms-sample">
                    <div className="row mb-3">
                      <label for="exampleInputUsername2" className="col-sm-3 col-form-label">
                        Username
                      </label>
                      <div className="col-sm-9">
                        <input type="text" className="form-control" id="exampleInputUsername2" placeholder="Email" />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label for="exampleInputEmail2" className="col-sm-3 col-form-label">
                        Email
                      </label>
                      <div className="col-sm-9">
                        <input type="email" className="form-control" id="exampleInputEmail2" autocomplete="off" placeholder="Email" />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label for="exampleInputMobile" className="col-sm-3 col-form-label">
                        Mobile
                      </label>
                      <div className="col-sm-9">
                        <input type="number" className="form-control" id="exampleInputMobile" placeholder="Mobile number" />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label for="exampleInputPassword2" className="col-sm-3 col-form-label">
                        Password
                      </label>
                      <div className="col-sm-9">
                        <input type="password" className="form-control" id="exampleInputPassword2" autocomplete="off" placeholder="Password" />
                      </div>
                    </div>
                    <div className="form-check mb-3">
                      <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                      <label className="form-check-label" for="exampleCheck1">
                        Remember me
                      </label>
                    </div>
                    <button type="submit" className="btn btn-primary me-2">
                      Submit
                    </button>
                    <button className="btn btn-secondary">Cancel</button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h6 className="card-title">Inputs</h6>
                  <form>
                    <div className="mb-3">
                      <label for="exampleInputText1" className="form-label">
                        Text Input
                      </label>
                      <input type="text" className="form-control" id="exampleInputText1" value="Amiah Burton" placeholder="Enter Name" />
                    </div>

                    <div className="mb-3">
                      <label for="exampleInputEmail3" className="form-label">
                        Email Input
                      </label>
                      <input type="email" className="form-control" id="exampleInputEmail3" value="amiahburton@gmail.com" placeholder="Enter Email" />
                    </div>

                    <div className="mb-3">
                      <label for="exampleInputNumber1" className="form-label">
                        Number Input
                      </label>
                      <input type="number" className="form-control" id="exampleInputNumber1" value="6473786" />
                    </div>

                    <div className="mb-3">
                      <label for="exampleInputPassword3" className="form-label">
                        Password Input
                      </label>
                      <input type="password" className="form-control" id="exampleInputPassword3" value="amiahburton" placeholder="Enter Password" />
                    </div>

                    <div className="mb-3">
                      <label for="exampleInputDisabled1" className="form-label">
                        Disabled Input
                      </label>
                      <input type="text" className="form-control" id="exampleInputDisabled1" disabled value="Amiah Burton" />
                    </div>

                    <div className="mb-3">
                      <label for="exampleInputPlaceholder" className="form-label">
                        Placeholder
                      </label>
                      <input type="text" className="form-control" id="exampleInputPlaceholder" placeholder="Enter Your Name" />
                    </div>

                    <div className="mb-3">
                      <label for="exampleInputReadonly" className="form-label">
                        Readonly
                      </label>
                      <input type="text" className="form-control" id="exampleInputReadonly" readonly value="Amiah Burton" />
                    </div>

                    <div className="mb-3">
                      <label for="exampleFormControlSelect1" className="form-label">
                        Select Input
                      </label>
                      <select className="form-select" id="exampleFormControlSelect1">
                        <option selected disabled>
                          Select your age
                        </option>
                        <option>12-18</option>
                        <option>18-22</option>
                        <option>22-30</option>
                        <option>30-60</option>
                        <option>Above 60</option>
                      </select>
                    </div>

                    <div className="mb-3">
                      <label for="exampleFormControlSelect2" className="form-label">
                        Example multiple select
                      </label>
                      <select multiple className="form-select" id="exampleFormControlSelect2">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                      </select>
                    </div>

                    <div className="mb-3">
                      <label for="exampleFormControlTextarea1" className="form-label">
                        Example textarea
                      </label>
                      <textarea className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                    </div>

                    <div className="mb-3">
                      <label for="customRange1" className="form-label">
                        Range Input
                      </label>
                      <input type="range" className="form-range" id="formRange1" />
                    </div>

                    <div className="mb-4">
                      <div className="form-check mb-2">
                        <input type="checkbox" className="form-check-input" id="checkDefault" />
                        <label className="form-check-label" for="checkDefault">
                          Default checkbox
                        </label>
                      </div>
                      <div className="form-check mb-2">
                        <input type="checkbox" className="form-check-input" id="checkChecked" checked />
                        <label className="form-check-label" for="checkChecked">
                          Checked
                        </label>
                      </div>
                      <div className="form-check mb-2">
                        <input type="checkbox" className="form-check-input" id="checkDisabled" disabled />
                        <label className="form-check-label" for="checkDisabled">
                          Disabled checkbox
                        </label>
                      </div>
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="checkCheckedDisabled" checked />
                        <label className="form-check-label" for="checkCheckedDisabled">
                          Disabled checked
                        </label>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="form-check form-check-inline">
                        <input type="checkbox" className="form-check-input" id="checkInline" />
                        <label className="form-check-label" for="checkInline">
                          Inline checkbox
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="checkbox" className="form-check-input" id="checkInlineChecked" checked />
                        <label className="form-check-label" for="checkInlineChecked">
                          Checked
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="checkbox" className="form-check-input" id="checkInlineDisabled" disabled />
                        <label className="form-check-label" for="checkInlineDisabled">
                          Inline disabled checkbox
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="checkbox" className="form-check-input" id="checkInlineCheckedDisabled" checked />
                        <label className="form-check-label" for="checkInlineCheckedDisabled">
                          Disabled checked
                        </label>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="form-check mb-2">
                        <input type="radio" className="form-check-input" name="radioDefault" id="radioDefault" />
                        <label className="form-check-label" for="radioDefault">
                          Default
                        </label>
                      </div>
                      <div className="form-check mb-2">
                        <input type="radio" className="form-check-input" name="radioDefault" id="radioDefault1" />
                        <label className="form-check-label" for="radioDefault1">
                          Default
                        </label>
                      </div>
                      <div className="form-check mb-2">
                        <input type="radio" className="form-check-input" name="radioSelected" id="radioSelected" checked />
                        <label className="form-check-label" for="radioSelected">
                          Selected
                        </label>
                      </div>
                      <div className="form-check mb-2">
                        <input type="radio" className="form-check-input" name="radioDisabled" id="radioDisabled" disabled />
                        <label className="form-check-label" for="radioDisabled">
                          Disabled
                        </label>
                      </div>
                      <div className="form-check">
                        <input type="radio" className="form-check-input" name="radioSelectedDisabled" id="radioSelectedDisabled" disabled checked />
                        <label className="form-check-label" for="radioSelectedDisabled">
                          Selected and disabled
                        </label>
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="form-check form-check-inline">
                        <input type="radio" className="form-check-input" name="radioInline" id="radioInline" />
                        <label className="form-check-label" for="radioInline">
                          Default
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" className="form-check-input" name="radioInline" id="radioInline1" />
                        <label className="form-check-label" for="radioInline1">
                          Default
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" className="form-check-input" name="radioInlineSelected" id="radioInlineSelected" checked />
                        <label className="form-check-label" for="radioInlineSelected">
                          Selected
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input type="radio" className="form-check-input" name="radioInlineDisabled" id="radioInlineDisabled" disabled />
                        <label className="form-check-label" for="radioInlineDisabled">
                          Disabled
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          name="radioInlineSelectedDisabled"
                          id="radioInlineSelectedDisabled"
                          disabled
                          checked
                        />
                        <label className="form-check-label" for="radioInlineSelectedDisabled">
                          Selected and disabled
                        </label>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="form-check form-switch mb-2">
                        <input type="checkbox" className="form-check-input" id="formSwitch1" />
                        <label className="form-check-label" for="formSwitch1">
                          Toggle this switch element
                        </label>
                      </div>
                      <div className="form-check form-switch">
                        <input type="checkbox" className="form-check-input" disabled id="formSwitch2" />
                        <label className="form-check-label" for="formSwitch2">
                          Disabled switch element
                        </label>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="form-label" for="formFile">
                        File upload
                      </label>
                      <input className="form-control" type="file" id="formFile" />
                    </div>
                    <button className="btn btn-primary" type="submit">
                      Submit form
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
