import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
// import Users from "../Users";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert2";

export default function EditWord(props) {
  // const { id } = useParams();
  const { id , onUpdate} = props

  console.log("%%%", id)
  const navigate = useNavigate();
  const ClicktoBack = () => {
    navigate("/words");
  };
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    word: "",
    paraphrase: "",
    category_id: "",
    language_id: "",
  });

  const [category_ids, setCategory_id] = useState();

  const [CategoryData, setCategoryData] = useState([]);
  const [LanguageData, setLanguageData] = useState([]);

  const fetchUser = async () => {
    try {
      const api = await axios.get("/api/v1/adm/words/" + id);
      setFormData(api.data.results);
      console.log(api.data.results);
    } catch (err) {
      console.log("Something Wrong");
    }
  };

  useEffect(() => {
    fetchUser();
  }, [id]);

  console.log("####", formData);
  console.log("####LL", LanguageData);

  // const handleChange = event => {
  //   setFormData({ ...formData, [event.target.name]: event.target.value });
  // };
  const handleChange = event => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const onSubmitChange = event => {
    event.preventDefault();

    axios
      .put(`/api/v1/adm/words/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(response => {
        // console.log(response.data);
        swal.fire("Success!", "Word has been successfully updated.", "success");
        onUpdate(response.data.results);
        // navigate("/words");
      })
      .catch(error => {
        console.log(error);
        if (error.response.status == 422) {
          setErrors(error.response.data.errors);
        } else {
          swal.fire({
            title: "Unable to update item",
            text: error.message,
            icon: "error",
          });
        }
      });
  };


  useEffect(() => {
    ListCategory("");
    FetchLanguage();
  }, []);

  const ListCategory = query => {
    axios
      .get(`/api/v1/adm/category?search=${query}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(response => {
        //console.log(response.data.results);

        setCategoryData(response.data.results);
      })
      .catch(error => {
        console.log(error);
        swal.fire({
          title: "Unable to fetch data",
          text: error.message,
          icon: "error",
        });
      });
  };

  const FetchLanguage = () => {
    axios
      .get(`/api/v1/adm/languages`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(response => {
        //console.log(response.data.results);

        setLanguageData(response.data.results);
      })
      .catch(error => {
        console.log(error);
        swal.fire({
          title: "Unable to fetch data - Languages",
          text: error.message,
          icon: "error",
        });
      });
  };

  return (
    // <Layout>

    <>
      <div className="modal-dialog">
        <div className="modal-content" style={{ borderRadius: "4px" }}>
          <div className="modal-header bg-primary">
            <h5 className="modal-title text-black" id="exampleModalLabel" style={{ fontWeight: "bold" }}>Word Edit</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="row">
            <div >
              <div className="card">
                <div className="card-body">


                  <form onSubmit={e => onSubmitChange(e)} className="forms-sample">
                    <div className="mb-3">
                      <label htmlFor="exampleInputUsername1" className="form-label">
                        Word
                      </label>
                      <input
                        type="text"
                        name="word"
                        value={formData.word || ""}
                        onChange={handleChange}
                        className="form-control"
                        id="exampleInputUsername1"
                        autoComplete="off"
                        placeholder="Word"
                      />
                      {errors.word && <div className="text-danger mt-1">{errors.word[0]}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleInputEmail1" className="form-label">
                        Paraphrase
                      </label>
                      <input
                        type="text"
                        name="paraphrase"
                        value={formData.paraphrase || ""}
                        onChange={handleChange}
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Paraphrase"
                      />
                      {errors.paraphrase && <div className="text-danger mt-1">{errors.paraphrase[0]}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleInputPassword1" className="form-label">
                        Category
                      </label>

                      <select onChange={handleChange} name="category_id" className="form-control form-select" id="exampleInputPassword1" placeholder="Category">
                        <option>--Select--</option>
                        {CategoryData.map((category, i) => (
                          <option key={i} selected={formData.category_id === category.id} value={category.id}>
                            {category.category_name}
                          </option>
                        ))}
                      </select>

                      {errors.category && <div className="text-danger mt-1">{errors.category[0]}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleInputL" className="form-label">
                        Language
                      </label>
                      <select onChange={handleChange} required name="language_id" className="form-control form-select" id="exampleInputL" placeholder="Language">
                        <option>Select Language</option>
                        {LanguageData.map((language, i) => (

                          <option key={i} selected={formData.language_id === language.id} value={language.id}>
                            {language.language_name}
                          </option>

                        ))}
                      </select>
                      {errors.language && <div className="text-danger mt-1">{errors.language[0]}</div>}
                    </div>
                    {/* <button type="submit" className="btn btn-primary me-2">
                            Submit
                          </button>
                          <button className="btn btn-secondary" onClick={ClicktoBack}>
                            Cancel
                          </button> */}
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">Save changes</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </>


  );
}
