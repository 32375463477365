import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";

import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
export default function View() {
  const { id } = useParams();
  // console.log(id);
  const [user, setUser] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchUser();
  }, [id]);

  const fetchUser = async () => {
    try {
      const api = await axios.get(`/api/v1/users/show/${id}`);
      // console.log(api.data.results);
      setUser(api.data.results);
    } catch (err) {
      console.log("something went wrong!");
    }
  };

  const clickToBackHandler = () => {
    navigate("/users");
  };
  return (
    <Layout>
      <div className="page-content">
        <nav className="page-breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              Forms
            </li>
            <li className="breadcrumb-item " aria-current="page">
              Users Details
            </li>
          </ol>
        </nav>

        <div className="row">
          <div className="col-md-10 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">User Details</h6>
                <button className="btn btn-primary" onClick={clickToBackHandler}>
                  Back To List
                </button>
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      <td>{user.id}</td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
