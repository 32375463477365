import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';

const LineChart = () => {
    const chartRef = useRef(null);

    useEffect(() => {
        if (!chartRef.current) return;

        const chart = Highcharts.chart(chartRef.current, {
            title: {
                text: 'Predictive analytics for Students',
                align: 'left'
            },
            subtitle: {
                text: 'Month-wise',
                align: 'left'
            },
            colors: [
                '#4caefe',
                '#3fbdf3',
                '#35c3e8',
                '#2bc9dc',
                '#20cfe1',
                '#16d4e6',
                '#0dd9db',
                '#03dfd0',
                '#00e4c5',
                '#00e9ba',
                '#00eeaf',
                '#23e274'
            ],
            xAxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            },
            yAxis: {
                title: {
                    text: 'values'
                },
                labels: {
                    format: '{value}'
                }
            },
            series: [{
                type: 'column',
                name: 'Values',
                borderRadius: 5,
                colorByPoint: true,
                data: [1200, 1100, 900, 700, 1000, 600, 800, 400,
                    500, 300, 200, 100],
                showInLegend: false
            }]
        });

        const plainButton = document.getElementById('plain');
        const invertedButton = document.getElementById('inverted');

        const updateChart = (options) => {
            chart.update(options);
        };

        plainButton.addEventListener('click', () => {
            updateChart({
                chart: {
                    inverted: false,
                    polar: false
                },
                subtitle: {
                    text: 'Chart option: Plain | Source: ' +
                        '<a href="https://www.nav.no/no/nav-og-samfunn/statistikk/arbeidssokere-og-stillinger-statistikk/helt-ledige"' +
                        'target="_blank">NAV</a>'
                }
            });
        });

        invertedButton.addEventListener('click', () => {
            updateChart({
                chart: {
                    inverted: true,
                    polar: false
                },
                subtitle: {
                    text: 'Chart option: Inverted | Source: ' +
                        '<a href="https://www.nav.no/no/nav-og-samfunn/statistikk/arbeidssokere-og-stillinger-statistikk/helt-ledige"' +
                        'target="_blank">NAV</a>'
                }
            });
        });

        return () => {
            // Clean up event listeners
            plainButton.removeEventListener('click', () => { });
            invertedButton.removeEventListener('click', () => { });
        };
    }, []);

    return (
        <div>
            <div id="container" ref={chartRef}></div>
            <button id="plain">Plain</button>
            <button id="inverted">Inverted</button>
        </div>
    );
};

export default LineChart;
