import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
// import Users from "../Users";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert2";

export default function EditUser() {
  const { id } = useParams();
  const navigate = useNavigate();
  const ClicktoBack = () => {
    navigate("/users");
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const fetchUser = async () => {
    try {
      const api = await axios.get(`/api/v1/users/show/${id}`)
      setFormData(api.data.results);
      console.log(api.data.results);
    } catch (err) {
      console.log("Something Wrong");
    }
  };

  useEffect(() => {
    fetchUser();
  }, [id]);

  const handleChange = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  // const onSubmitChange = async e => {
  //   e.preventDefault();
  //   try {
  //     const responce = await axios.put("http://127.0.0.1:8000/api/v1/users/update/" + id, formData);
  //     console.log(responce);
  //     alert("User successfully updated!");
  //     navigate("/users");
  //     //setLoading(true);
  //   } catch (err) {
  //     console.log("Something Wrong");
  //   }
  // };

  const onSubmitChange = event => {
    event.preventDefault();

    axios
      .put(`/api/v1/users/update/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(response => {
        console.log(response.data);
        swal.fire("Success!", "User has been successfully updated.", "success");
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Layout>
      <div className="page-content">
        <nav className="page-breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              Forms
            </li>
            <li className="breadcrumb-item " aria-current="page">
              Basic Elements
            </li>
          </ol>
        </nav>

        <div className="row">
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">Basic Form</h6>

                <form onSubmit={e => onSubmitChange(e)} className="forms-sample">
                  <div className="mb-3">
                    <label htmlFor="exampleInputUsername1" className="form-label">
                      Username
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name || ""}
                      onChange={handleChange}
                      className="form-control"
                      id="exampleInputUsername1"
                      autoComplete="off"
                      placeholder="Username"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email address
                    </label>
                    <input type="email" name="email" value={formData.email || ""} onChange={handleChange} className="form-control" id="exampleInputEmail1" placeholder="Email" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputPassword1" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      autoComplete="off"
                      placeholder="Password"
                      value={formData.password || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <button type="submit" className="btn btn-primary me-2">
                    Submit
                  </button>
                  <button onClick={ClicktoBack} className="btn btn-secondary">
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
