
import React, { useState, useEffect ,useRef} from 'react';
import Layout from '../../components/Layout';
import swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import BulkAddQuestionFile from './BulkAddQuestionFile';

const BulkAddQuestions = () => {
    const [questionData, setQuestionData] = useState({
        question_title: '',
        question_background: '',
        teachers_explanation_url: '',
        question_type: '',
        category_id: '',
        language_id: '',
    });

    const [CategoryData, setCategoryData] = useState([]);
    const [LanguageData, setLanguageData] = useState([]);
    const [vip_question, setVip_question] = useState(false);
    const [question_id, setQuestion_id] = useState("");
    const bulkAddQuestionFileRef = useRef(null);

    const handleCheckboxChange = () => {
        setVip_question(!vip_question);
    };
    const {  question_title , question_background ,
        teachers_explanation_url,
        question_type,
        category_id,
        language_id } = questionData
    console.log("questionData", questionData);
    const navigate = useNavigate();

    useEffect(() => {
        ListCategory();
        FetchLanguage();
    }, []);

    const FetchLanguage = () => {
        axios
            .get(`/api/v1/adm/languages`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            })
            .then(response => {
                setLanguageData(response.data.results);
            })
            .catch(error => {
                console.log(error);
                swal.fire({
                    title: 'Unable to fetch data - Languages',
                    text: error.message,
                    icon: 'error',
                });
            });
    };

    const ListCategory = query => {
        axios
            .get(`/api/v1/adm/category?search=${query}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            })
            .then(response => {
                setCategoryData(response.data.results);
            })
            .catch(error => {
                console.log(error);
                swal.fire({
                    title: 'Unable to fetch data - Categories',
                    text: error.message,
                    icon: 'error',
                });
            });
    };

    const handleInputChange = event => {
        const { name, value } = event.target;
        setQuestionData({
            ...questionData,
            [name]: value,
        });
    };

    const handleSubmit = async event => {
        event.preventDefault();
        try {
            // Send data to your backend API
            const data = {
                question_title,
                question_background,
                teachers_explanation_url,
                question_type,
                category_id,
                language_id,
                vip_question
            }
            const response = await axios.post('/api/v1/adm/question-create', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            if (response?.data?.results?.question_id) {
                // navigate(`/add-question/audio/${response?.data?.results?.question_id}`)
                setQuestion_id(response?.data?.results?.question_id);
            }
            localStorage.setItem("question_title", response?.data?.results?.question_title);
            swal.fire({
                title: 'Success',
                text: 'Question added successfully!',
                icon: 'success',
            });

        } catch (error) {
            // Handle error
            console.error('Error adding question:', error);
            swal.fire({
                title: 'Error',
                text: 'Unable to add question. Please try again.',
                icon: 'error',
            });
        }
    };
    const handleGoBack = () => {
        window.history.back();
    };
    useEffect(() => {
        if (question_id) {
          // Scroll to the BulkAddQuestionFile component when question_id is not blank
          bulkAddQuestionFileRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, [question_id]);
    return (
        <Layout>
            <div className='page-content'>
                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">

                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="card-title add-bulk">Add Bulk Questions Dialogus</div>
                                    <button className="btn btn-primary" onClick={handleGoBack}>
                                        Back
                                    </button>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    {/* <div className="mb-3">
                                        <h5>Question Type &amp; Info</h5>
                                    </div> */}
                                    <div className="mb-3">
                                        <label
                                            htmlFor="question_title"
                                            className="form-label"
                                            title="Question keywords (please enter meaningful keywords, the number of words should not exceed 50, which is convenient for search later)"
                                        >
                                            Question keywords (please enter meaningful keywords, the number of
                                            words should not exceed 50, which is convenient for search later)
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="question_title"
                                            name="question_title"
                                            value={question_title}
                                            onChange={handleInputChange}
                                            aria-required="true"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="question_background"
                                            className="form-label"
                                            title="Question background (please enter the topic background introduction)"
                                        >
                                            Question background (please enter the topic background
                                            introduction)
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="question_background"
                                            name="question_background"
                                            value={question_background}
                                            onChange={handleInputChange}
                                            aria-required="true"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="teachers_explanation_url"
                                            className="form-label"
                                            title="Teacher's explanation address (please enter the full video URL)"
                                        >
                                            Teacher's explanation address (please enter the full video URL)
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="teachers_explanation_url"
                                            name="teachers_explanation_url"
                                            value={teachers_explanation_url}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="questionType"
                                            className="form-label"
                                            title="Question Type"
                                        >
                                            Question Type
                                        </label>
                                        <select
                                            className="form-select"
                                            id="questionType"
                                            aria-required="true"
                                            value={question_type}
                                            onChange={handleInputChange}
                                            name="question_type"
                                        >
                                            {/* Add your options here */}
                                            <option ></option>
                                            <option value="1">Interpreter</option>
                                            <option value="2">Translator</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="category"
                                            className="form-label"
                                            title="Choose Category"
                                        >
                                            Choose Category
                                        </label>
                                        <select className="form-select" value={category_id}
                                            onChange={handleInputChange}
                                            name="category_id" id="category" aria-required="true">
                                            <option></option>
                                            {CategoryData.map((category, i) => {
                                                return <option key={category.id} value={category.id}>{category.category_name}</option>;
                                            })}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="language"
                                            className="form-label"
                                            title="Select Language"
                                        >
                                            Choose Language
                                        </label>
                                        <select className="form-select" value={language_id}
                                            onChange={handleInputChange}
                                            name="language_id" id="language" aria-required="true">
                                            <option></option>
                                            {LanguageData.map((language, i) => {
                                                return <option key={language.id} value={language.id}>{language.language_name}</option>;
                                            })}
                                        </select>
                                    </div>
                                    <div className="mb-3 mt-3 d-flex">
                                    {!question_id ?<>
                                        <div className="col-md-6 col-lg-6 col-sm-6 text-start">
                                            <input
                                                type="checkbox"
                                                checked={vip_question}
                                                onChange={handleCheckboxChange}
                                                className="p-4 mt-2 fs-8"
                                            />
                                            <span className="p-2">For VIP Question</span>
                                            {/* <p>{isChecked ? 'Checkbox is checked' : 'Checkbox is not checked'}</p> */}
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-sm-6 text-start justify-content-center">
                                            <button type="submit" className="btn btn-primary">
                                                Submit
                                            </button>
                                        </div></> :""}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={bulkAddQuestionFileRef}>
                {!question_id ? "": <BulkAddQuestionFile data={question_id}/>}
                </div>
            </div>
        </Layout>
    )
}

export default BulkAddQuestions