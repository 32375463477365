import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import axios from 'axios';
import swal from 'sweetalert2';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

const AssignNewUser = () => {
    const [selectedPlan, setSelectedPlan] = useState('');
    const [selectedStudent, setSelectedStudent] = useState('');
    const [students, setStudents] = useState([]);
    const [plans, setPlans] = useState([]);
    const [expiryDate, setExpiryDate] = useState('');
    // const [menuIsOpen, setMenuIsOpen] = useState(false);

    const handleChange = (selectedOption) => {
        setSelectedStudent(selectedOption.value);
    };
    const navigate = useNavigate();

    const handlePlanChange = (selectedOption) => {
        setSelectedPlan(selectedOption.value);
    };

    const fetchData = async (query = "") => {
        console.log("ZXJHv" , query)
        const url = `/api/v1/adm/user-email-list?email=${query}`;
        try {
            const api = await axios(url);
            setStudents(api.data.results.data);
        } catch (err) {
            console.log("something went wrong!");
        }
    };

    const fetchDataPlan = async (query = "") => {
        const url = `/api/v1/adm/user-access-plan-list`;
        try {
            const api = await axios(url);
            setPlans(api.data.results);
        } catch (err) {
            console.log("something went wrong!");
        }
    };

    useEffect(() => {
        fetchData();
        fetchDataPlan();
    }, []);

    const handleExpiryDateChange = (event) => {
        setExpiryDate(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const data = {
                user_id: selectedStudent,
                plan_id: selectedPlan,
                expiry_date: expiryDate
            }

            const response = await axios.post(`/api/v1/adm/user-plan-access`, data, {
                headers: {
                    "Content-Type": "application/json",
                },
            })

            swal.fire({
                title: "User access assigned successfully!",
                text: "success",
                icon: "success",
            });
            navigate("/manage-user/access");
        } catch (error) {
            console.log("error", error.response.data.message);
            swal.fire({
                title: error.response.data.message,
                text: error.message,
                icon: "error",
            });
        }
    };

    const handleGoBack = () => {
        window.history.back();
    };

    return (
        <Layout>
            <div className='page-content'>
                <div className='d-flex mb-0'>
                    <div className='col-md-6 col-lg-6 text-start'><h5>Assign New User</h5></div>
                    <div className='col-md-6 col-lg-6 text-end'><button className='btn btn-primary' onClick={handleGoBack}> Back</button></div>
                </div>
                <div className="container mt-2">
                    <div className="row justify-content-center bg-light">
                        <div className="col-md-10">
                            <form onSubmit={handleSubmit}>
                                <input
                                    type="hidden"
                                    name="_token"
                                    defaultValue="CLpr1T6PMLaic83H39gC58FLs4oQiW0QTBBB9rFU"
                                />
                                <div id="vertical-form" className="p-5">
                                    <div className="row g-4">
                                        <div className="col-md-6">
                                            <label htmlFor="plan_id" className="form-label">
                                                Select Plan <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <Select
                                                value={plans.find(plan => plan.value === selectedPlan)}
                                                onChange={handlePlanChange}
                                                options={plans.map(plan => ({ value: plan.id, label: plan.price + " / " + plan.plan_name + " / " + "Mocktest Limit" + " / " + plan.mocktest_limits }))}
                                                isSearchable
                                                placeholder="Select & Search..."
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="plan_id" className="form-label">
                                                Select Student <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <Select
                                                value={students.find(student => student.value === selectedStudent)}
                                                onChange={handleChange}
                                                options={students.map(student => ({ value: student.user_id, label: student.email }))}
                                                isSearchable
                                                placeholder="Select & Search..."
                                            />
                                        </div>
                                        {/* <div className="col-md-6">
                                            <label htmlFor="task_namex" className="form-label" id="task_namex">
                                                Select Student <span style={{ color: 'red' }}>*</span>
                                            </label>
                                            <Select
                                                value={students.find(student => student.value === selectedStudent)}
                                                onChange={handleChange}
                                                options={students.map(student => ({ value: student.id, label: student.email }))}
                                                isSearchable
                                            />
                                        </div> */}
                                        <div className="col-md-6">
                                            <label htmlFor="datetimepicker" className="form-label">
                                                Select Expiry Date <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={expiryDate}
                                                onChange={handleExpiryDateChange}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <button type="submit" className="btn btn-primary mt-2">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default AssignNewUser;
