import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import swal from "sweetalert2";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import PuffLoader from "react-spinners/PuffLoader";

export default function MockTestList() {
    const [wordData, setwordData] = useState([]);
    const [showTotal, setTotalShow] = useState([]);
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setperPage] = useState(10);
    const [totalPages, setotalPages] = useState(0);

    useEffect(() => {
        fetchData(currentPage, query, perPage);
    }, [query]);

    const fetchData = async (currentPage, query = "", perPage = "") => {
        const url = `/api/v1/adm/mocktest-list/?search=${query}&page=${currentPage}&perpage=${perPage}`;
        try {
            const api = await axios(url);
            setLoading(false);
            // console.log(api.data.results);
            setwordData(api?.data?.results?.data);
            setCurrentPage(currentPage);
            setotalPages(api.data.results.last_page);
            setTotalShow("Showing " + api.data.results.from + " to " + api.data.results.to + " of " + api.data.results.total + " entries");
        } catch (err) {
            setLoading(false);
            console.log("something went wrong!");
        }
    };

    const handlePageClick = async data => {
        let currentPagex = data;
        // console.log(currentPagex);
        setLoading(true);
        // setperPage(10);
        fetchData(currentPagex, query, perPage);
    };

    const handleSearch = event => {
        setQuery(event.target.value);
        setLoading(true);
        // fetchData(0, event.target.value);
    };

    const handlePerPageChange = event => {
        setLoading(true);
        let perpage = event.target.value;
        setperPage(perpage);
        console.log(perpage);
        fetchData("", query, perpage);
    };

    const deleteItem = async id => {
        console.log(id);
        try {
            const api = await axios.delete(`https://api.visionlanguageexperts.in/api/v1/adm/mocktest-delete?mock_id=${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });
            const newwordData = wordData.filter(item => {
                return item.id !== id;
            });
            // alert(api.data.message);
            swal.fire("Deleted!", "Mock test has been successfully Deleted.", "success");
            setwordData(newwordData);
        } catch (err) {
            // console.log("something went wrong!");
            swal.fire({
                title: "Error deleting item",
                text: err.message,
                icon: "error",
            });
        }
    };

    const handleDelete = id => {
        swal
            .fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            })
            .then(result => {
                if (result.isConfirmed) {
                    deleteItem(id);
                }
            });
    };

    const handleSwitchToggle = async (id) => {
        try {
            await axios.put(`/api/v1/adm/mocktest-status?mocktest_id=${id}`);
            swal.fire("Updated!", "Status has been updated successfully.", "success");
            fetchData(currentPage, query, perPage);
        } catch (error) {
            // Handle errors, show an error message, etc.
            console.error("Error updating switch status:", error);
            swal.fire({
                title: "Error",
                text: "Failed to update switch status.",
                icon: "error",
            });
        }
    };

    return (
        <Layout>
            <div className="page-content">
                <div className="row">
                    <div className="col-md-12 col-lg-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="card-title text-start">Mock Test List</h6>
                                <div className="col-sm-12 col-md-12">
                                    <Link to="/add-mock/test" className="btn btn-primary btn-icon-text float-end">
                                        {/* <Icon.Plus className="btn-icon-prepend" /> */}
                                        Add Mock Test
                                    </Link>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-sm-12 col-md-8"></div>
                                    <div className="col-sm-12 col-md-4">
                                        <div className="dataTables_paginate paging_simple_numbers" id="dataTableExample_paginate">
                                            <input value={query} onChange={handleSearch} type="search" className="form-control" placeholder="Search" aria-controls="dataTableExample" />
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    {loading ? (
                                        <div className="data_loader">
                                            <PuffLoader color="#6571ff" />
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                    <div className="col-sm-12 col-md-4">
                                        <label className="form-check-label mx-1">Show</label>
                                        <select className="form-select form-select-sm w-25 d-sm-inline-block mx-2" name="perpage" onChange={handlePerPageChange}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                        </select>
                                        <label className="form-check-label"> entries</label>
                                    </div>
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Mock_test_name</th>
                                                <th>Language_name</th>
                                                <th>Category</th>
                                                <th>Duration</th>
                                                <th>Status</th>
                                                <th>Manage Question</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {wordData.map((mocktest, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <th>{mocktest.serial_no}</th>
                                                        <td>{mocktest.mocktest_name}</td>
                                                        <td>{mocktest.language_name}</td>
                                                        <td>{mocktest.category_name}</td>
                                                        <td>{mocktest.duration} Min</td>
                                                        <td className=" form-switch" >
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={mocktest.mocktest_status === 1}
                                                                onChange={() => handleSwitchToggle(mocktest.id)}
                                                                style={{ cursor: "pointer" }}
                                                            />
                                                        </td>
                                                        <td style={{ padding: "0px", paddingTop: "5px" }}>
                                                            <Link to={`/mock-test/set/list/${mocktest.id}?languageId=${mocktest.language_id}&languageName=${mocktest.language_name}`}>
                                                                <button className="btn btn-primary">Manage</button>
                                                            </Link>
                                                            {/* <Link to={`/mock-test/set/list/${mocktest.id}`}>
                                                            <button className="btn btn-primary">Manage</button>
                                                            </Link> */}
                                                        </td>
                                                        <td style={{ padding: "0px" }}>
                                                            <Link className="btn" to={`/update-mock/test/${mocktest.id}`}>
                                                                <Icon.Edit className="link-icon" />
                                                            </Link>
                                                            <button onClick={() => handleDelete(mocktest.id)} className="btn">
                                                                <Icon.Trash className="link-icon" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-sm-12 col-md-7">
                                        <div className="dataTables_info" id="dataTableExample_info" role="status" aria-live="polite">
                                            {showTotal}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-5">
                                        <div className="dataTables_paginate paging_simple_numbers" id="dataTableExample_paginate">
                                            <ResponsivePagination current={currentPage} total={totalPages} onPageChange={handlePageClick} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
