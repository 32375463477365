import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from '../../components/Layout';
import swal from "sweetalert2";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import { useNavigate } from 'react-router-dom';

const AddMocktestSet = () => {
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [CategoryData, setCategoryData] = useState([]);
    const [showTotal, setTotalShow] = useState([]);
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setperPage] = useState(10);
    const [totalPages, setotalPages] = useState(0);
    const [cat_id, setCat_id] = useState('');

    const mocktest_id = localStorage.getItem("mocktest_id");
    const language_id = localStorage.getItem("language_id");
    const language_name = localStorage.getItem("language_name");
    const catgeory_id = localStorage.getItem("catgeory_id");

    const navigate = useNavigate();
    // Fetch the list of items from your API
    const getMocktestQuestionList = (currentPage, query = "", perPage = "") => {
        axios.get(`/api/v1/adm/question-list?language_id=${language_id}&category_id=${cat_id}&search=${query}&page=${currentPage}&perpage=${perPage}`)
            .then(response => {
                setLoading(false);
                setItems(response?.data?.results?.data);
                setCurrentPage(currentPage);
                setotalPages(response.data.results.last_page);
                setTotalShow("Showing " + response.data.results.from + " to " + response.data.results.to + " of " + response.data.results.total + " entries");
            })
            .catch(error => {
                setLoading(false)
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        if (language_id && cat_id) {
            getMocktestQuestionList(currentPage, query, perPage);
        }
    }, [query]);

    const handleSearchBycat = () => {
        setLoading(true)
        if (language_id && cat_id) {
            getMocktestQuestionList(currentPage, query, perPage);
        }
    }

    const handlePageClick = async data => {
        let currentPagex = data;
        // console.log(currentPagex);
        // setLoading(true);
        // setperPage(10);
        getMocktestQuestionList(currentPagex, query, perPage);
    };

    const handleItemSelect = (itemId) => {
        // Toggle the selected state of the item
        setSelectedItems(prevSelectedItems => {
            if (prevSelectedItems.includes(itemId)) {
                return prevSelectedItems.filter(id => id !== itemId);
            } else {
                return [...prevSelectedItems, itemId];
            }
        });
    };

    const handleSearch = event => {
        setQuery(event.target.value);
        setLoading(true);
        // fetchData(0, event.target.value);
    };

    const handleSendData = () => {
        // Send selected item IDs to your API
        const myArray = selectedItems;
        const arrayAsString = myArray.join(', ');
        const data = {
            mocktest_id: mocktest_id,
            question_id: arrayAsString
        }
        axios.post('/api/v1/adm/mocktest-set-create', data)
            .then(response => {
                swal.fire("Success!", "Mock test set has been successfully created.", "success");
                navigate("/mock-test/list");
                console.log('Data sent successfully:', response.data);
            })
            .catch(error => {
                swal.fire("Warning!", "Mock test set not created! somthing went roungh.", "error");
                console.error('Error sending data:', error);
            });
    };

    useEffect(() => {
        ListCategory();
    }, []);

    const ListCategory = query => {
        axios
            .get(`/api/v1/adm/category?search=${query}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            })
            .then(response => {
                //console.log(response.data.results);
                setCategoryData(response.data.results);
            })
            .catch(error => {
                console.log(error);
                swal.fire({
                    title: "Unable to fetch data - Categories",
                    text: error.message,
                    icon: "error",
                });
            });
    };

    return (
        <Layout>
            <div className='page-content'>
                <div className='text-start bg-light p-3 rounded'><h5>Questions List</h5></div>
                <div className='d-flex p-2'>
                    <div className='col-md-2 col-lg-2 mt-2 text-start'>
                        Language : {language_name}
                    </div>
                    <div className='col-lg-2 col-md-2 mt-0 text-start'>
                        <select
                            className="form-select filter"
                            value={cat_id}
                            onChange={(e) => setCat_id(e.target.value)}
                            placeholder="Please Select catagory"
                            name="cat_id"
                        >
                            <option value="" disabled>Select Catagory</option>
                            {CategoryData.map((Catagory, i) => (
                                <option key={i} value={Catagory.id}>
                                    {Catagory.category_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-md-4 col-lg-4'> <input value={query} onChange={handleSearch} type="search" className="form-control" placeholder="Search" aria-controls="dataTableExample" /></div>
                    <div className='col-md-2 col-lg-2 text-center'> {language_id && cat_id && (
                        <button className="btn btn-primary mt-0" onClick={handleSearchBycat}>
                            Search
                        </button>
                    )}</div>
                    <div className='col-md-2 col-lg-2 text-end'>
                    </div>
                </div>
                <div className='col-lg-12 col-md-12 text-start'>{selectedItems.length > 0 && (
                    <button className="btn btn-primary mt-0" onClick={handleSendData}>
                        Create Mocktest
                    </button>
                )}</div>
                <table className="table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th scope="">Question Title</th>
                            <th scope="col">Question Background </th>
                            <th scope="col">Language</th>
                            <th scope="col">Category</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(item => (
                            <tr key={item.id}>
                                <td style={{ width: "0px" }}>
                                    <input
                                        type="checkbox"
                                        checked={selectedItems.includes(item.question_id)}
                                        onChange={() => handleItemSelect(item.question_id)}
                                        style={{ width: '15px', height: '15px', cursor: "pointer" }}
                                    />
                                </td>
                                <td>{item.question_title}</td>
                                <td>{item.question_background}</td>
                                <td>{item.language_name}</td>
                                <td>{item.category_name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="row mt-3">
                    <div className="col-sm-12 col-md-7">
                        <div className="dataTables_info" id="dataTableExample_info" role="status" aria-live="polite">
                            {showTotal}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-5">
                        <div className="dataTables_paginate paging_simple_numbers" id="dataTableExample_paginate">
                            <ResponsivePagination current={currentPage} total={totalPages} onPageChange={handlePageClick} />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default AddMocktestSet;
