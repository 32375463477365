import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import PuffLoader from "react-spinners/PuffLoader";

export default function EnquireList() {
    const [userData, setUserData] = useState([]);
    const [showTotal, setTotalShow] = useState([]);
    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setotalPages] = useState(0);
    const [perPage, setperPage] = useState(10);

    useEffect(() => {
        fetchData(currentPage, query, perPage);
    }, []);

    const fetchData = async (currentPage, query = "", perPage = "") => {
        const url = `/api/v1/adm/online-class-user-list/?search=${query}&page=${currentPage}&perpage=${perPage}`;

        try {
            const api = await axios(url);
            setLoading(false);
            // console.log(api.data.results);
            setUserData(api.data.results.data);
            // console.log(api.data.results.data);
            setCurrentPage(currentPage);
            setotalPages(api.data.results.last_page);
            setTotalShow("Showing " + api.data.results.from + " to " + api.data.results.to + " of " + api.data.results.total + " entries");
        } catch (err) {
            setLoading(false);
            console.log("something went wrong!");
        }
    };

    const handlePageClick = async data => {
        let currentPagex = data;
        console.log(currentPagex);
        setLoading(true);
        fetchData(currentPagex, query, perPage);
    };

    const [query, setQuery] = useState("");

    const handleSearch = event => {
        setQuery(event.target.value);
        setLoading(true);
        fetchData(0, event.target.value);
    };

    const handlePerPageChange = event => {
        setLoading(true);
        let perpage = event.target.value;
        setperPage(perpage);
        console.log(perpage);
        fetchData("", query, perpage);
    };

    return (
        <Layout>
            <div className="page-content">
                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="card-title text-start">Enquire List</h6>
                                <div className="row mt-3">
                                    <div className="col-sm-12 col-md-8"></div>
                                    {/* <div className="col-sm-12 col-md-4">
                                        <div className="dataTables_paginate paging_simple_numbers" id="dataTableExample_paginate">
                                            <input value={query} onChange={handleSearch} type="search" className="form-control float-end" placeholder="Search" aria-controls="dataTableExample" />
                                        </div>
                                    </div> */}
                                </div>
                                <div className="table-responsive">
                                    {loading ? (
                                        <div className="data_loader">
                                            <PuffLoader color="#6571ff" />
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                    <div className="col-sm-12 col-md-4">
                                        <label className="form-check-label mx-1">Show</label>
                                        <select className="form-select form-select-sm w-25 d-sm-inline-block mx-2" name="perpage" onChange={handlePerPageChange}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                        </select>
                                        <label className="form-check-label"> entries</label>
                                    </div>
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                {/* <th>User ID</th> */}
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>message</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userData.map((user, i) => {
                                                const created_date = new Date(user?.created_at);
                                                return (
                                                    <tr key={i}>
                                                        <th>{user?.serial_no}</th>
                                                        {/* <th>{user.user_id}</th> */}
                                                        <td>{user.first_name}</td>
                                                        <td>{user.email}</td>
                                                        <td>{user.phone}</td>
                                                        <td>{user.message}</td>
                                                        <td>{created_date == "Invalid Date" ? "" : created_date.toLocaleString("en-IN", { month: "2-digit", day: "2-digit", year: "numeric" })}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-sm-12 col-md-7">
                                        <div className="dataTables_info" id="dataTableExample_info" role="status" aria-live="polite">
                                            {showTotal}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-5">
                                        <div className="dataTables_paginate paging_simple_numbers" id="dataTableExample_paginate">
                                            <ResponsivePagination current={currentPage} total={totalPages} onPageChange={handlePageClick} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
