import React from "react";
import Layout from "../components/Layout";
const Home = () => {
  return (
    <>
      <Layout>
        <div className="page-content">
          <h1>Blank Page</h1>
        </div>
      </Layout>
    </>
  );
};

export default Home;
