
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import { Link } from "react-router-dom";
import swal from "sweetalert2";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import PuffLoader from "react-spinners/PuffLoader";

export default function UserComments() {
    const [wordData, setwordData] = useState([]);
    const [showTotal, setTotalShow] = useState([]);
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setperPage] = useState(10);
    const [totalPages, setotalPages] = useState(0);
    const [neww, setNeww] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);

    useEffect(() => {
        fetchData(currentPage, query, perPage);
    }, [query, neww]);

    const fetchData = async (currentPage, query = "", perPage = "") => {
        const url = `/api/v1/adm/forum-list?search=${query}&page=${currentPage}&perpage=${perPage}`;
        try {
            const api = await axios(url);
            setLoading(false);
            // console.log(api.data.results);
            setwordData(api?.data?.results?.forum_list?.data);
            setCurrentPage(currentPage);
            setotalPages(api.data.results.last_page);
            setTotalShow("Showing " + api.data.results.from + " to " + api.data.results.to + " of " + api.data.results.total + " entries");
        } catch (err) {
            setLoading(false);
            console.log("something went wrong!");
        }
    };

    const handlePageClick = async data => {
        let currentPagex = data;
        // console.log(currentPagex);
        setLoading(true);
        // setperPage(10);
        fetchData(currentPagex, query, perPage);
    };

    const handleSearch = event => {
        setQuery(event.target.value);
        setLoading(true);
        // fetchData(0, event.target.value);
    };

    const handlePerPageChange = event => {
        setLoading(true);
        let perpage = event.target.value;
        setperPage(perpage);
        console.log(perpage);
        fetchData("", query, perpage);
    };

    const deleteItem = async id => {
        try {
            const api = await axios.delete(`/api/v1/adm/forum-delete?forum_id=${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });
            const newwordData = wordData.filter(item => {
                return item.id !== id;
            });
            // alert(api.data.message);
            swal.fire("Deleted!", "Comment has been successfully Deleted.", "success");
            setwordData(newwordData);
            setNeww(true)
        } catch (err) {
            // console.log("something went wrong!");
            swal.fire({
                title: "Error deleting item",
                text: err.message,
                icon: "error",
            });
        }
    };

    const handleDelete = id => {
        swal
            .fire({
                title: "Are you sure?",
                text: "You won't be delete this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            })
            .then(result => {
                if (result.isConfirmed) {
                    deleteItem(id);
                }
            });
    };

    const toggleRow = (id) => {
        setExpandedRows((prevRows) => {
            const isRowExpanded = prevRows.includes(id);
            return isRowExpanded ? prevRows.filter((rowId) => rowId !== id) : [...prevRows, id];
        });
    };

    return (
        <Layout>
            <div className="page-content">
                <div className="row">
                    <div className="col-md-12 col-lg-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="col-md-10 col-lg-10 card-title text-start">User Comment</h6>
                                </div>
                                <div className="d-flex mt-3">
                                    {/* <div className="col-sm-12 col-md-4 col-lg-4">
                                        <label className="form-check-label mx-1">Show</label>
                                        <select className="form-select form-select-sm w-25 d-sm-inline-block mx-2" name="perpage" onChange={handlePerPageChange}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                        </select>
                                        <label className="form-check-label"> entries</label>
                                    </div> */}
                                    <div className="col-sm-1 col-md-4 col-lg-4"></div>
                                    <div className="col-sm-12 col-md-4 col-lg-4">
                                        <div className="dataTables_paginate paging_simple_numbers" id="dataTableExample_paginate">
                                            <input value={query} onChange={handleSearch} type="search" className="form-control" placeholder="Search" aria-controls="dataTableExample" />
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    {loading ? (
                                        <div className="data_loader">
                                            <PuffLoader color="#6571ff" />
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nick Name</th>
                                                <th>Picture</th>
                                                <th>Comments</th>
                                                <th>Date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {wordData.map((mocktest, i) => {
                                                const created_date = new Date(mocktest?.created_at);
                                                const created_dates = new Date(mocktest?.expiry_date);
                                                const isRowExpanded = expandedRows.includes(mocktest.id);
                                                return (
                                                    <>
                                                        <tr key={i}>
                                                            <th>{i + 1}</th>
                                                            <td>{mocktest?.get_user?.name}</td>
                                                            <td>
                                                                <img src={mocktest?.forum_image} alt="Forum Image" style={{ width: "50px", height: "50px" }} />
                                                            </td>
                                                            <td>{mocktest?.reply_message}</td>
                                                            <td>{created_date.toDateString()}</td>
                                                            <td style={{ padding: "0px" }}>
                                                                <i class="ti ti-trash-off"></i>
                                                                <Link className="btn" to="">
                                                                    <i class="ti ti-trash-off"></i>
                                                                    <button className="btn btn-danger mt-1" style={{ padding: "1px", paddingLeft: "6px", paddingRight: "6px", fontSize: "12px" }} onClick={() => handleDelete(mocktest.id)}>Delete</button>
                                                                </Link>
                                                                <button
                                                                    className="btn btn-success mt-1"
                                                                    style={{ padding: "1px", paddingLeft: "6px", paddingRight: "6px", fontSize: "12px" }}
                                                                    onClick={() => toggleRow(mocktest.id)}
                                                                >
                                                                    {isRowExpanded ? "Hide Replies" : "Show Replies"}
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        {isRowExpanded && (
                                                            <>{mocktest.get_replies.map((reply, index) => {
                                                                const created_date = new Date(reply?.created_at);
                                                                return (
                                                                    <tr style={{ background: "lightgray" }}>
                                                                        <th>{index + 1}</th>
                                                                        <td>{reply?.get_user?.name}</td>
                                                                        <td>{reply?.forum_id}</td>
                                                                        <td>{reply?.reply_message}</td>
                                                                        <td>{created_date.toDateString()}</td>
                                                                        <td>
                                                                            <button className="btn btn-danger mt-1" style={{ padding: "1px", paddingLeft: "6px", paddingRight: "6px", fontSize: "12px" }} onClick={() => handleDelete(reply.id)}>Delete</button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                            </>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row mt-3">
                                    {/* <div className="col-sm-12 col-md-7">
                                        <div className="dataTables_info" id="dataTableExample_info" role="status" aria-live="polite">
                                            {showTotal}
                                        </div>
                                    </div> */}
                                    <div className="col-sm-12 col-md-5">
                                        <div className="dataTables_paginate paging_simple_numbers" id="dataTableExample_paginate">
                                            <ResponsivePagination current={currentPage} total={totalPages} onPageChange={handlePageClick} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
