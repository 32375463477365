
import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import axios from 'axios';
import swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';

const UserUpdatePlanAccess = () => {
    const [selectedPlan, setSelectedPlan] = useState('');
    const [selectedStudent, setSelectedStudent] = useState('');
    const [mail, setMail] = useState('');
    const [data, setData] = useState("");
    const [students, setStudents] = useState([]);
    const [plans, setPlans] = useState([]);
    const [expiryDate, setExpiryDate] = useState('');

    const { id } = useParams();

    const fetchData = async (query = "") => {
        const url = `/api/v1/adm/user-plan-access-details?user_subscribe_id=${id}`;
        try {
            const api = await axios(url);
            setData(api.data.results);
            setSelectedStudent(api?.data?.results?.user_id || '');
            setSelectedPlan(api?.data?.results?.plan_id || '');
            const created_date = new Date(api?.data?.results?.expiry_date);
            created_date.setDate(created_date.getDate() + 1); // Adding one day
            const formattedDate = created_date.toISOString().split('T')[0]; // Format as "YYYY-MM-DD"
            setExpiryDate(formattedDate || '');
            setMail(api?.data?.results?.get_users?.email || '');
        } catch (err) {
            console.log("something went wrong!");
        }
    };
    
    const navigate = useNavigate();
    // const handleChange = (selectedOption) => {
    //     setSelectedStudent(selectedOption.value);
    // };

    const fetchDataEmail = async (query = "") => {
        const url = `/api/v1/adm/user-email-list?email=${query}`;
        try {
            const api = await axios(url);
            setStudents(api.data.results.data);
        } catch (err) {
            console.log("something went wrong!");
        }
    };

    const fetchDataPlan = async () => {
        const url = `/api/v1/adm/user-access-plan-list`;
        try {
            const api = await axios(url);
            setPlans(api.data.results);
        } catch (err) {
            console.log("something went wrong!");
        }
    };

    useEffect(() => {
        fetchData();
        fetchDataPlan();
        fetchDataEmail();
    }, [id]);

    const handlePlanChange = (event) => {
        setSelectedPlan(event.target.value);
    };

    const handleExpiryDateChange = (event) => {
        setExpiryDate(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const data = {
                user_id: selectedStudent,
                plan_id: selectedPlan,
                expiry_date: expiryDate,
                user_subscribe_id: id
            }

            const response = await axios.post(`/api/v1/adm/update-user-plan-access`, data, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            swal.fire({
                title: "User access assigned Plan Updated successfully!",
                text: "success",
                icon: "success",
            });
            navigate("/manage-user/access");

        } catch (error) {
            console.log(error);
            swal.fire({
                title: "Unable to fetch data",
                text: error.message,
                icon: "error",
            });
        }
    };

    return (
        <Layout>
            <div className='page-content'>
                <div>
                    <h5>Update User Plan Access</h5>
                </div>
                <div className="container mt-5">
                    <div className="row justify-content-center bg-light">
                        <div className="col-md-10">
                            <form
                                onSubmit={handleSubmit}
                            >
                                <div id="vertical-form" className="p-5">
                                    <div className="row g-4">
                                        <div className="col-md-6">
                                            <label htmlFor="plan_id" className="form-label">
                                                Select Plan <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <div style={{ position: 'relative' }}>
                                                <select
                                                    value={selectedPlan}
                                                    onChange={handlePlanChange}
                                                    className="form-control"
                                                    required
                                                >
                                                    <option value="" disabled>Select Plan</option>
                                                    {plans.map((plan) => (
                                                        <option key={plan.id} value={plan.id}>{plan.plan_name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="plan_id" className="form-label">
                                                Select Student <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <div className='col-md-12 col-lg-12'>
                                                <input type='text' value={mail} style={{ width: "100%", height: "37px" }} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="datetimepicker" className="form-label">
                                                Select Expiry Date <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={expiryDate}
                                                onChange={handleExpiryDateChange}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <button type="submit" className="btn btn-primary mt-2">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
export default UserUpdatePlanAccess;

