import React, { useState } from 'react';
import Layout from '../../components/Layout';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert2';

const AddVideo = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    video_category: '',
    video_title: '',
    video_url: '',
    description: '',
  });

  const ClicktoBack = () => {
    navigate('/video');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      
      const response = await axios.post('/api/v1/adm/video-store', formData);
      swal.fire("Success!", "Video upload successfully.", "success");
      navigate('/video');
    } catch (error) {
      console.error('Error updating video:', error);
    }
  };

  return (
    <Layout>
      <div className='page-content'>
        <div className="container mt-2">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h6 className="col-md-10 col-lg-10 card-title text-start">Add New</h6>
            <button className="btn btn-primary" onClick={ClicktoBack}>
              Back
            </button>
          </div>
          <div className="card p-5">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="category_name" className="form-label">
                  Choose Category <span style={{ color: "red" }}>*</span> :
                </label>
                <select
                  className="form-select"
                  name="video_category"
                  id="video_category"
                  value={formData.video_category}
                  onChange={handleChange}
                >
                  <option value="" disabled="" selected="">
                    Please Choose...
                  </option>
                  <option value="Speaking">Speaking</option>
                  <option value="Writing">Writing</option>
                  <option value="Reading">Reading</option>
                  <option value="Listening">Listening</option>
                  <option value="Full Mock Test">Full Mock Test</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Title <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="name"
                  placeholder="Enter Name"
                  className="form-control"
                  name="video_title"
                  type="text"
                  value={formData.video_title}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="video_url" className="form-label">
                  Video URL<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="video_url"
                  placeholder="Video URL"
                  className="form-control"
                  name="video_url"
                  type="text"
                  value={formData.video_url}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Description <span style={{ color: "red" }}
                  >*</span>
                  </label>
                  <textarea
                    id="description"
                    placeholder="Description"
                    className="form-control"
                    rows={2}
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                  />
                </div>
                <button type="submit" className="btn btn-primary mt-4">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    );
  };
  
  export default AddVideo;
  