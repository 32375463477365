import React from "react";
import "./App.css"
import Home from "./pages/Home";
// import Dashboard from "./pages/Dashboard";
import TabbedScreen from "./components/TabbedScreen";
import Form from "./pages/Form";
import Login from "./pages/Login";
import Users from "./pages/Users";
import AddUsers from "./pages/users/AddUsers";
import View from "./pages/users/View";
import EditUser from "./pages/users/EditUsers";
import Words from "./pages/words/Words";
import WordAdd from "./pages/words/WordAdd";
import EditWord from "./pages/words/EditWord";
import AddQuestions from "./pages/questions/AddQuestion";
import Questions from "./pages/questions/Questions";
import BulkAddQuestions from "./pages/questions/BulkAddQuestions";
import EditQuestion from "./pages/questions/EditQuestion";
import AddMockTest from "./pages/MockTest/AddMockTest";
import MockTestList from "./pages/MockTest/MockTestList";
import UpdateMockTest from "./pages/MockTest/UpdateMockTest";
import AddMocktestSet from "./pages/MockTest/AddMocktestSet";
import MockTestSetList from "./pages/MockTest/MockTestSetList";
import MockTestAnalysis from "./pages/MockAnalysis/MockTestAnalysis";
import MockTestAnalysisSubmit from "./pages/MockAnalysis/MockTestAnalysisSubmit";
import BulkAddQuestionFile from "./pages/questions/BulkAddQuestionFile";
import AddAudioBulkFile from "./pages/questions/AddAudioBulkFile";
import Plan from "./pages/Plan/Plan";
import ManageUserAccess from "./pages/ManageUserAccess/ManageUserAccess";
import AssignNewUser from "./pages/ManageUserAccess/AssignNewUser";
import UserUpdatePlanAccess from "./pages/ManageUserAccess/UserUpdatePlanAccess";
import AddPlan from "./pages/Plan/AddPlan";
import UserComments from "./pages/UserComments/UserComments";
import UpdatePlan from "./pages/Plan/UpdatePlan";
import Video from "./pages/Video/Video";
import AddVideo from "./pages/Video/AddVideo";
import UpdateVideo from "./pages/Video/UpdateVideo";
import EnquireList from "./pages/OnlineClassInquire/EnquireList";
import AddTopUp from "./pages/ManageUserAccess/AddTopUp";
import WordListImport from "./pages/words/WordListImport";
import AddUser from "./PteScreens/ManageUsers/AddUser";

import { Routes, Route } from "react-router-dom";

import axios from "axios";

// axios.defaults.baseURL = "http://localhost:8000";
axios.defaults.baseURL = "https://api.visionlanguageexperts.in";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";

axios.defaults.headers.get["Content-Type"] = "application/json";
axios.defaults.headers.get["Accept"] = "application/json";

axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  // config.headers.common["X-CSRF-Token"] = token;
  return config;
});

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/dashboard" element={<TabbedScreen />} />
        <Route path="/form" element={<Form />} />
        <Route path="/users" element={<Users />} />
        <Route path="/add-users" element={<AddUsers />} />
        <Route path="/users-view/:id" element={<View />} />
        <Route path="/users-edit/:id" element={<EditUser />} />
        <Route path="/words" element={<Words />} />
        <Route path="/word-add" element={<WordAdd />} />
        <Route path="/word-edit/:id" element={<EditWord />} />
        <Route path="/add-question" element={<AddQuestions />} />
        <Route path="/questions" element={<Questions />} />
        <Route path="/add-questions" element={<BulkAddQuestions />} />
        <Route path="/question-edit/:id" element={<EditQuestion />} />
        <Route path="/add-mock/test" element={<AddMockTest />} />
        <Route path="/mock-test/list" element={<MockTestList />} />
        <Route path="/update-mock/test/:id" element={<UpdateMockTest />} />
        <Route path="/add-mock-test/set" element={<AddMocktestSet />} />
        <Route path="/mock-test/set/list/:id" element={<MockTestSetList />} />
        <Route path="/mock-test/analysis" element={<MockTestAnalysis />} />
        <Route path="/mock-test/analysis/submit/:id" element={<MockTestAnalysisSubmit />} />
        <Route path="/add-question/audio/:id" element={<BulkAddQuestionFile />} />
        <Route path="/add-audio/file" element={<AddAudioBulkFile />} />
        <Route path="/plan" element={<Plan />} />
        <Route path="/manage-user/access" element={<ManageUserAccess />} />
        <Route path="/assign-new/user" element={<AssignNewUser />} />
        <Route path="/user-update-plan/access/:id" element={<UserUpdatePlanAccess />} />
        <Route path="/add-plan" element={<AddPlan />} />
        <Route path="/user-comments" element={<UserComments />} />
        <Route path="/update-plan/:id" element={<UpdatePlan />} />
        <Route path="/video" element={<Video />} />
        <Route path="/add-video" element={<AddVideo />} />
        <Route path="/update-video/:id" element={<UpdateVideo />} />
        <Route path="/enquire-list" element={<EnquireList />} />
        <Route path="/add-topup/:id" element={<AddTopUp />} />
        <Route path="/word-list-import" element={<WordListImport />} />
        <Route path="/pte-add-user" element={<AddUser />} />
      </Routes>
    </div>
  );
}

export default App;
