import React from 'react'
import Layout from '../../components/Layout'

const AddUser = () => {
    return (
        <Layout>
            <div className="page-content">
                <div className="container">
                    <div className="card p-3">
                        <div id="student_message" />
                        <div className="text-end text-danger">
                            <i className="fa fa-hand-o-right" /> ( * ) All Fields are Mandatory
                        </div>
                        <form
                            id="adduser_form"
                            method="POST"
                            action="https://beta.visionlanguageexperts.com.au/admin/user/store"
                        >
                            <input
                                type="hidden"
                                name="_token"
                                defaultValue="QeTom8pcfrp7dDOOO3R5oh1bgbuPvEY8xRx7aIQv"
                            />
                            <div className="row g-3 mt-5">
                                <div className="col-sm-6">
                                    <label className="form-label">
                                        First Name <span className="text-danger">*</span> :
                                    </label>
                                    <input
                                        type="text"
                                        id="firstname"
                                        name="firstname"
                                        className="form-control"
                                        placeholder="Enter First Name"
                                        defaultValue=""
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <label className="form-label">
                                        Last Name <span className="text-danger">*</span> :
                                    </label>
                                    <input
                                        type="text"
                                        id="lastname"
                                        name="lastname"
                                        className="form-control"
                                        placeholder="Enter Last Name"
                                        defaultValue=""
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="Email" className="form-label">
                                        Email <span className="text-danger">*</span> :
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="form-control"
                                        placeholder="Enter email"
                                        defaultValue=""
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="Mobile" className="form-label">
                                        Mobile <span className="text-danger">*</span> :
                                    </label>
                                    <input
                                        type="text"
                                        id="mobile"
                                        name="mobile"
                                        className="form-control"
                                        placeholder="Enter Mobile"
                                        defaultValue=""
                                        required=""
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="Role" className="form-label">
                                        Role<span className="text-danger">*</span> :
                                    </label>
                                    <select required="" name="role" className="form-select">
                                        <option value={2} selected="selected">
                                            Admin
                                        </option>
                                        <option value={5}>Staff</option>
                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="Password" className="form-label">
                                        Password <span className="text-danger">*</span> :
                                    </label>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            id="password"
                                            name="password"
                                            className="form-control"
                                            required=""
                                            placeholder="Enter Password"
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary"
                                            onclick="generatePassword()"
                                        >
                                            Generate
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            onclick="copyToClipboard()"
                                        >
                                            Copy
                                        </button>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label className="form-label">Address</label>
                                    <textarea
                                        id="address"
                                        name="address"
                                        className="form-control"
                                        placeholder=""
                                        rows={2}
                                        cols={50}
                                        defaultValue={""}
                                    />
                                </div>
                                <div className="col-12 text-center">
                                    <button type="submit" className="btn btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AddUser