import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import axios from 'axios';
import swal from 'sweetalert2';

const MockTestAnalysisSubmit = () => {
    const [data, setData] = useState("");
    const [loading, setLoading] = useState(false);
    const [textValues, setTextValues] = useState([]);
    const [twitter_remark, setTwitter_remark] = useState([]);
    const { id } = useParams();

    const fetchData = async () => {
        const url = `/api/v1/adm/mocktest-record-details?mocktest_record_id=${id}`
        try {
            const api = await axios(url);
            setLoading(false);
            setData(api?.data?.results);
            // setTextValues(api?.data?.results?.mocktest_record_details);
            const initialTextValues = api?.data?.results?.mocktest_record_details?.map(item => ({
                mocktestSetId: item.mocktest_set_id,
                value: item.mocktest_review.reviews || '', // Assuming the review data is available in the 'review' property
            })) || [];
            const initialTextValuess = api?.data?.results?.mocktest_record_details?.map(item => ({
                mocktestSetId: item.mocktest_set_id,
                value: item.mocktest_review.twitter_remark || '', // Assuming the review data is available in the 'review' property
            })) || [];
            setTextValues(initialTextValues);
            setTwitter_remark(initialTextValuess);
        } catch (err) {
            setLoading(false);
            console.log("something went wrong!");
        }
    };

    const created_date = new Date(data?.mocktest_details?.created_at);

    useEffect(() => {
        fetchData();
    }, []);

    const handleTextAreaChange = (mocktestSetId, value) => {
        // Find the index of the item in the array
        const index = textValues.findIndex(item => item.mocktestSetId === mocktestSetId);
        // If the item exists, update its value; otherwise, add a new item to the array
        if (index !== -1) {
            setTextValues(prevValues => [
                ...prevValues.slice(0, index),
                { mocktestSetId, value },
                ...prevValues.slice(index + 1)
            ]);
        } else {
            setTextValues(prevValues => [...prevValues, { mocktestSetId, value }]);
        }
        console.log(`MockTest Set ID ${mocktestSetId} - Textarea Value: ${value}`);
    };
    const handleTextAreaChanges = (mocktestSetId, value) => {
        // Find the index of the item in the array
        const index = textValues.findIndex(item => item.mocktestSetId === mocktestSetId);
        // If the item exists, update its value; otherwise, add a new item to the array
        if (index !== -1) {
            setTwitter_remark(prevValues => [
                ...prevValues.slice(0, index),
                { mocktestSetId, value },
                ...prevValues.slice(index + 1)
            ]);
        } else {
            setTwitter_remark(prevValues => [...prevValues, { mocktestSetId, value }]);
        }
        console.log(`MockTest Set ID ${mocktestSetId} - Textarea Value: ${value}`);
    };

    const handleSubmit = () => {
        // Handle the submit action for all text area values and corresponding mocktest set IDs
        console.log("Submit clicked", textValues);
        if (!textValues.length == 0) {
            axios.post('/api/v1/adm/mocktest-review-submit', { mocktest_reviews: textValues, twitter_remarks: twitter_remark, mocktest_record_id: id })
                .then(response => {
                    console.log("Analysis submitted successfully:", response.data);
                    swal.fire("Success!", "Mock test Reviews Submitted Successfully.", "success");
                    // You can handle success here
                })
                .catch(error => {
                    swal.fire(
                        {
                            title: error.response.data.message,
                            text: "Please check your review",
                            icon: "error",
                        }
                    );
                    // You can handle errors here
                });
        } else {
            swal.fire("Error!", "Write your reviews this is required.", "error");
        }
        // Example: Send data to an API
    };

    const handleCancel = () => {
        // Handle the cancel action
        console.log("Cancel clicked");
        setTextValues([]);
    };
    const handleGoBack = () => {
        window.history.back();
    };

    return (
        <Layout>
            <div className='page-content'>
                <div className=" card container mt-0">
                    <div className=" text-start d-flex">
                        <div className="card-body col-lg-4 col-md-4">
                            <h5 className="card-title">Mock Test Title : {data?.mocktest_details?.mocktest_name}</h5>
                            <p className="card-text">Test Date - <b>{created_date == "Invalid Date" ? "" : created_date.toLocaleString("en-IN", { month: "2-digit", day: "2-digit", year: "numeric" })}</b></p>
                            <p className="card-text">Student Name - <b>{data?.user_details?.name + " " + data?.user_details?.last_name}</b></p>
                            <p className="card-text">Student ID - <b>{data?.user_details?.student_id}</b></p>
                            <p className="card-text">Email ID - <b>{data?.user_details?.email}</b></p>
                        </div>
                        <div className='col-lg-3 col-md-3 p-4'> Review Status : {data?.mocktest_details?.feedback_status == 1 ? <span style={{ fontSize: "18px" }}>Submitted</span> : "Pending"}</div>
                        <div className='col-lg-1 col-md-1 p-4'><button className='btn btn-primary' onClick={handleGoBack}>Back</button></div>
                    </div>
                </div>
                <div className='container mt-4'>
                    <div>
                        {data?.mocktest_record_details?.map((item) => (
                            <div key={item?.mocktest_set_id} className=''>
                                <h4 className=' p-4 bg-light'>{item?.question_lists?.question_title}</h4>
                                {item?.segment_list?.map((segment, i) => (
                                    <div key={segment.id} className='text-start'>
                                        <hr />
                                        <h4 className='segmentHeading'>{i + 1}</h4>
                                        <div className='p-3 d-flex'>
                                            <div className='col-lg-2 col-md-2 mt-3'><h5> Question Attempt Count : </h5> </div>
                                            {segment.attempt === 0 ? <div className=' text-end  fs-3 mt-2 mb-0'>0</div> :
                                                <div> <h3 style={{padding:13,fontWeight:"500"}}>{segment.attempt}</h3></div>}
                                        </div>
                                        <div className='p-3 d-flex'>
                                            <div className='col-lg-2 col-md-2 mt-3'><h5>User Answer</h5> </div>
                                            {segment.attempt === 0 ? <div className=' text-end text-danger fs-5 mt-3 mb-0'>Not Attempt</div> :
                                                <div> <audio controls src={`${segment.user_answer}`} preload="none"></audio></div>}
                                        </div>
                                        <div className='p-3 d-flex'>
                                            <div className='col-lg-2 col-md-2 mt-3'><h5>Question Audio</h5> </div>
                                            <div><audio controls src={`${segment.question_audio}`} preload="none"></audio></div>
                                        </div>
                                        <div className='p-3 d-flex'>
                                            <div className='col-lg-2 col-md-2 mt-3'><h5>Sample Answer Audio</h5> </div>
                                            <div><audio controls src={`${segment?.sample_answer_audio}`} preload="none"></audio></div>
                                        </div>
                                        <p>{segment?.audio_reference_text}</p>
                                    </div>
                                ))}
                                <div className='mt-4 mb-4 text-start'>
                                    <label className='p-1 fs-5'><b>Please write your comment</b></label>
                                    <textarea
                                        required
                                        className='form-control'
                                        placeholder={`Enter your analysis for MockTest Set ID ${item.mocktest_set_id}`}
                                        value={textValues.find(val => val.mocktestSetId === item.mocktest_set_id)?.value || ''}
                                        onChange={(e) => handleTextAreaChange(item.mocktest_set_id, e.target.value)}
                                        style={{ height: "100px" }}
                                    />

                                    <label className='p-1 fs-5'><b>Please write your tutor remarks</b></label>
                                    <input type='text' className='form-control'
                                        value={twitter_remark.find(val => val.mocktestSetId === item.mocktest_set_id)?.value || ''}
                                        onChange={(e) => handleTextAreaChanges(item.mocktest_set_id, e.target.value)}
                                    />
                                </div>
                            </div>
                        ))}
                        <div className='mt-3 d-flex justify-content-start p-3'>
                            <button className='btn btn-primary me-2' onClick={handleSubmit}>Submit</button>
                            <button className='btn btn-secondary' onClick={handleGoBack}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default MockTestAnalysisSubmit;
