import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import swal from "sweetalert2";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import PuffLoader from "react-spinners/PuffLoader";
import { AiOutlineFilter } from "react-icons/ai";
import EditWord from "./EditWord";

export default function Words() {
  const [wordData, setwordData] = useState([]);
  const [showTotal, setTotalShow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hide, setHide] = useState(false);
  const [CategoryData, setCategoryData] = useState([]);
  const [language_id, setLanguage_id] = useState("");
  const [languages, setLanguages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setperPage] = useState(30);
  const [totalPages, setotalPages] = useState(0);
  const [query, setQuery] = useState("");
  const [category_id, setCategory_id] = useState('');
  const [wordDatas, setWordDatas] = useState([]);
  
  const handleWordUpdate = (updatedData) => {
    // Update the wordData state with the updated data
    setWordDatas(updatedData);
  };

  const fetchData = async (currentPage, query = "", perPage = "", language_id, category_id) => {
    const url = `api/v1/adm/words/?search=${query}&language_id=${language_id}&category_id=${category_id}&page=${currentPage}&perpage=${perPage}`;
    try {
      const api = await axios(url);
      setLoading(false);
      console.log("wertyu",api.data);
      setwordData(api.data.results.data);
      setHide(api?.data);
      setCurrentPage(currentPage);
      setotalPages(api.data.results.last_page);
      setTotalShow("Showing " + api.data.results.from + " to " + api.data.results.to + " of " + api.data.results.total + " entries");
    } catch (err) {
      setLoading(false);
      console.log("something went wrong!");
    }
  };

  useEffect(() => {
    fetchData(currentPage, query, perPage, language_id, category_id);
  }, [wordDatas,currentPage, query, perPage, language_id, category_id]);

  const handlePageClick = async data => {
    let currentPagex = data;
    // console.log(currentPagex);
    setLoading(true);
    // setperPage(10);
    fetchData(currentPagex, query, perPage, language_id, category_id);
  };

  const handleSearch = event => {
    setQuery(event.target.value);
    setLoading(true);
    fetchData(0, event.target.value);
  };

  const handlePerPageChange = event => {
    setLoading(true);
    let perpage = event.target.value;
    setperPage(perpage);
    console.log(perpage);
    fetchData("", query, perpage);
  };

  const searchUsers = query => {
    axios
      .get(`/api/v1/adm/words?search=${query}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(response => {
        console.log(response.data.results);

        setwordData(response.data.results);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const deleteItem = async id => {
    console.log(id);
    try {
      const api = await axios.delete("/api/v1/adm/words/" + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      const newwordData = wordData.filter(item => {
        return item.id !== id;
      });
      // alert(api.data.message);
      swal.fire("Deleted!", "word has been successfully Deleted.", "success");
      setwordData(newwordData);
    } catch (err) {
      // console.log("something went wrong!");
      swal.fire({
        title: "Error deleting item",
        text: err.message,
        icon: "error",
      });
    }
  };

  const handleDelete = id => {
    swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      .then(result => {
        if (result.isConfirmed) {
          deleteItem(id);
        }
      });
  };

  const handleLanguageSelect = (id) => {
    setLanguage_id(id);
  }

  const FetchLanguage = () => {
    axios
      .get(`/api/v1/languages`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => {
        //console.log(response.data.results);
        setLanguages(response?.data?.results);
      })
      .catch(error => {
        swal.fire({
          title: "Unable to fetch data - Languages",
          text: error.message,
          icon: "error",
        });
      });
  };

  const ListCategory = query => {
    axios
      .get(`/api/v1/adm/category?search=${query}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(response => {
        //console.log(response.data.results);
        setCategoryData(response.data.results);
      })
      .catch(error => {
        console.log(error);
        swal.fire({
          title: "Unable to fetch data - Categories",
          text: error.message,
          icon: "error",
        });
      });
  };

  useEffect(() => {
    FetchLanguage();
    ListCategory();
  }, []);

  return (
    <Layout>
      <div className="page-content">
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title text-start">WORD LIST</h6>
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-2 col-lg-2">
                    {/* <label className="">Show</label> */}
                    <select className="form-select form-select-sm w-35    d-sm-inline-block   " name="perpage" onChange={handlePerPageChange}>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    {/* <label className="">Show</label> */}
                  </div>
                  <div className="col-sm-12 col-md-2 col-lg-2">
                    <input value={query} onChange={handleSearch} type="search" className="form-control" placeholder="Search" aria-controls="dataTableExample" />
                  </div>
                  {hide?.hide === true ? "" :  <div className="language-filter col-sm-12 col-md-2 col-lg-2" >
                    {/* <span className="fs-3 p-0"><AiOutlineFilter /></span> */}
                    <select
                      className="language-list form-select" style={{ borderRadius: "5px", padding: "7.5px" }}
                      onChange={(e) => handleLanguageSelect(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Select Language
                      </option>
                      {languages.map((language) => (
                        <option key={language.id} value={language.id}>
                          {language.language_name}
                        </option>
                      ))}
                    </select>

                  </div>}
                 
                  <div className='col-lg-2 col-md-2 mt-0 text-start'>
                    <select
                      className="form-select filter"
                      value={category_id}
                      onChange={(e) => setCategory_id(e.target.value)}
                      placeholder="Please Select catagory"
                      name="category_id"
                    >
                      <option value="" disabled>Select category</option>
                      {CategoryData.map((Catagory, i) => (
                        <option key={i} value={Catagory.id}>
                          {Catagory.category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-4 col-md-2 col-lg-2 ">
                    <Link to="/word-add" className="btn btn-primary">
                      Add Word
                    </Link>
                  </div>
                  <div className="col-sm-4 col-md-2 col-lg-2 text-start">
                    <Link to="/word-list-import" className="btn btn-primary">
                      Imports Word
                    </Link>
                  </div>
                </div>
                <div className="table-responsive">
                  {loading ? (
                    <div className="data_loader">
                      <PuffLoader color="#6571ff" />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Word</th>
                        <th>Paraphrase</th>
                        <th>Category</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {wordData.map((words, i) => {
                        return (
                          <tr key={i}>
                            <th>{words?.serial_no}</th>
                            <td>{words.word}</td>
                            <td>{words.paraphrase}</td>
                            <td>{words.category.category_name}</td>
                            <td>
                              <button
                                className=""
                                style={{ border: "none", background: "none" }}
                                data-bs-toggle="modal"
                                data-bs-target={`#exampleModal-${words.id}`} // Unique identifier for modal
                              >
                                <Icon.Edit className="link-icon" />
                              </button>
                              <div className="modal fade modal-right" id={`exampleModal-${words.id}`} tabIndex="-1" aria-labelledby={`exampleModalLabel-${words.id}`} aria-hidden="true">
                                {/* Pass the id prop to the EditWord component */}
                                <EditWord id={words.id} key={words.id} onUpdate={handleWordUpdate} /> {/* Pass key prop */}
                              </div>
                              <button onClick={() => handleDelete(words.id)} className="btn" data-toggle="tooltip" data-placement="top" title="Delete Word">
                                <Icon.Trash className="link-icon" />
                              </button>
                            </td>
                          </tr>
                        );
                      })}

                    </tbody>
                  </table>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-7">
                    <div className="dataTables_info" id="dataTableExample_info" role="status" aria-live="polite">
                      {showTotal}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-5">
                    <div className="dataTables_paginate paging_simple_numbers" id="dataTableExample_paginate">
                      <ResponsivePagination current={currentPage} total={totalPages} onPageChange={handlePageClick} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
