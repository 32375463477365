import React, { useState } from 'react';
import Layout from '../../components/Layout';
import swal from 'sweetalert2';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import PuffLoader from "react-spinners/PuffLoader";

const BulkAddQuestionFile = (props) => {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const question_title = localStorage.getItem('question_title');
    const Mock_title = localStorage.getItem('Mock_title');
    // const { id } = useParams();
    const id = props.data

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        // Check if a file is selected
        if (selectedFile) {
            // Check if the selected file is of type XLSX
            if (selectedFile.name.endsWith('.xlsx')) {
                setFile(selectedFile);
            } else {
                swal.fire({
                    title: 'Invalid File Type',
                    text: 'Please select a valid XLSX file.',
                    icon: 'error',
                });
                // Optionally, you may reset the file input
                e.target.value = null;
            }
        }
    };

    const handleUpload = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('import_file', file);
            formData.append('question_id', id);

            const response = await axios.post('/api/v1/adm/segment-import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setLoading(false);
            swal.fire({
                title: 'Success',
                text: 'File uploaded successfully!',
                icon: 'success',
            });
            setFile(null);
            navigate("/questions");
        } catch (error) {
            setLoading(false);
            swal.fire({
                title: 'Error',
                text: 'Unable to upload file. Please try again.',
                icon: 'error',
            });
        }
    };
    const handleGoBack = () => {
        window.history.back();
    };

    return (
        // <Layout>
        //     <div className="page-content">
        <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                    {loading && (
                        <div className="data_loader">
                            <PuffLoader color="#6571ff" />
                        </div>
                    )}
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <div className="card-title add-bulk col-md-9 col-lg-9 text-start">File Upload</div>
                            {/* <button className="btn btn-primary mb-1" onClick={handleGoBack}>
                                        Back
                                    </button> */}
                        </div>
                        <div className="mb-3 text-start">
                            <h5>Question Title : <span className='fs-8'>{question_title ? question_title : Mock_title}</span></h5>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="file" className="form-label">
                                Choose XLSX File
                            </label>
                            <input
                                type="file"
                                className="form-control"
                                id="file"
                                accept=".xlsx"
                                onChange={handleFileChange}
                            />
                        </div>
                        <div className="mb-3 mt-3">
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn btn-primary" onClick={handleUpload}>
                                    Upload
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        //     </div>
        // </Layout>
    );
};

export default BulkAddQuestionFile;
