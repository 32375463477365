import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Chart from '../components/Chart';
import LineChart from '../components/LineChart';

const PteDashboard = () => {
    const [data, setData] = useState([]);

    const CardData = async () => {
        try {
            const response = await axios.get('/api/v1/pte/admin/home', {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setData(response?.data?.results || []); // Ensure data is an array
        } catch (error) {
            // Handle any errors here
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        CardData();
    }, []);

    return (
        <div className="row">
            <div className="col-12 col-xl-12 stretch-card ">
                <div className="row flex-grow-1">
                    <div className="col-12 col-sm-6 col-xl-3 intro-y mt-4 ">
                        <div className="card card-box box4 bg-info">
                            <div className="card-body inner">
                                <h3 className="card-title">1</h3>
                                <p className="card-text">Online Students</p>
                            </div>
                            <div className="icon">
                                <i className="fa fa-users" />
                            </div>
                            <a
                                href=""
                                className="card-link card-box-footer"
                            >
                                View More <i className="fa fa-arrow-circle-right" />
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-xl-3 intro-y mt-4 ">
                        <div className="card card-box box4 bg-info">
                            <div className="card-body inner">
                                <h3 className="card-title">1</h3>
                                <p className="card-text">Online Students</p>
                            </div>
                            <div className="icon">
                                <i className="fa fa-users" />
                            </div>
                            <a
                                href=""
                                className="card-link card-box-footer"
                            >
                                View More <i className="fa fa-arrow-circle-right" />
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-xl-3 intro-y mt-4 ">
                        <div className="card card-box box4 bg-info">
                            <div className="card-body inner">
                                <h3 className="card-title">1</h3>
                                <p className="card-text">Online Students</p>
                            </div>
                            <div className="icon">
                                <i className="fa fa-users" />
                            </div>
                            <a
                                href=""
                                className="card-link card-box-footer"
                            >
                                View More <i className="fa fa-arrow-circle-right" />
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-xl-3 intro-y mt-4 ">
                        <div className="card card-box box4 bg-info">
                            <div className="card-body inner">
                                <h3 className="card-title">1</h3>
                                <p className="card-text">Online Students</p>
                            </div>
                            <div className="icon">
                                <i className="fa fa-users" />
                            </div>
                            <a
                                href=""
                                className="card-link card-box-footer"
                            >
                                View More <i className="fa fa-arrow-circle-right" />
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-xl-3 intro-y mt-4 ">
                        <div className="card card-box box4 bg-info">
                            <div className="card-body inner">
                                <h3 className="card-title">1</h3>
                                <p className="card-text">Online Students</p>
                            </div>
                            <div className="icon">
                                <i className="fa fa-users" />
                            </div>
                            <a
                                href=""
                                className="card-link card-box-footer"
                            >
                                View More <i className="fa fa-arrow-circle-right" />
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-xl-3 intro-y mt-4 ">
                        <div className="card card-box box4 bg-info">
                            <div className="card-body inner">
                                <h3 className="card-title">1</h3>
                                <p className="card-text">Online Students</p>
                            </div>
                            <div className="icon">
                                <i className="fa fa-users" />
                            </div>
                            <a
                                href=""
                                className="card-link card-box-footer"
                            >
                                View More <i className="fa fa-arrow-circle-right" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-5'>
                <Chart />
            </div>
            <div className='mt-5'>
                <LineChart />
            </div>
        </div>
    )
}

export default PteDashboard